<template>
  <v-app>
    <Navbar />
    <v-content>
      <section class=" darken-2 pa-7" fixed-tabs style="background: #4CACBC;">
        <!-- <section class="blue darken-2 pa-5" fixed-tabs> -->
        <v-container>
          <v-row
            style="background: #E3F2FD;
            background: linear-gradient(to bottom, #1976D2, #1E88E5, #E3F2FD);"
            no-gutters
          ></v-row>
        </v-container>
      </section>
      <!-- login ไม่เชื่อม OneID -->
      <!-- ขนาดหน้าจอคอมพิวเตอร์ -->
      <div v-if="resolutionScreen >= 400">
        <section
          style="background: #E3F2FD;
        background: linear-gradient(to bottom, #4CACBC, #6CC4A1, #B2EBF2, #8dbff2);"
          fixed-tabs
        >
          <v-container v-if="checkbox_remember == false">
            <v-row no-gutters>
              <!-- เวอร์ชั่นใหม่ ปุ่มลงทะเบียนใช้ Etax -->
              <!-- <v-col class="text-center pa-0" style="margin-left:40px" cols="12" md="6">
                <v-layout>
                <div style="margin-left:-200px; margin-top:100px">
                  <v-btn
                    class="pa-3"
                    style="font-size:13px; color:white; background: linear-gradient(to right, #190A75, #3559FC); margin-top:0px; margin-left:0px;"
                    depressed
                    elevation="10"
                    rounded
                    @click="$router.push('/register')"
                  >
                  Click 
                  <v-icon color="#90FF7F">
                    mdi-gesture-tap
                  </v-icon>
                  ลงทะเบียนรับ etax บัญชีนิติบุคคล
                  </v-btn>
                  <img
                    class="mt-5"
                    style="margin-left:30px;"
                    src="@/assets/e-tax-text.png"
                    width="750px"
                  />
                </div>
                <div style="margin-left:-250px;">
                <h2
                  class="text-center ml-0 mt-0 mb-0 white--text"
                  style="font-size:45px; text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;"
                 v-if="system_type_run === 'UAT'"
                >
                  ONE BOX UAT
                </h2>
                <h2
                  class="text-center ml-0 mt-0 mb-0 white--text"
                  style="font-size:45px; text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;"
                  v-else
                >
                  ONE BOX
                </h2>
                <h2
                  class="text-center ml-0 mb-0 white--text"
                  style="font-size:21px; text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;"
                >
                  Human Trusted IT Service
                </h2>
                <v-row class="fill-height white--text" justify="center">
                  <v-col cols="12">
                    <v-container grids-list-xs>
                      <img
                        v-if="resolutionScreen >= 400"
                        src="@/assets/landinglogo2.png"
                        width="450px"
                      />
                      <img
                        v-else
                        src="@/assets/landinglogo2.png"
                        width="300px"
                      />
                    </v-container>
                  </v-col>
                </v-row>
                </div>
                </v-layout>
              </v-col> -->
              <!-- เวอร์ชั่นเก่า -->
              <v-col class="text-center pa-0" cols="12" md="6">
                <h2
                  class="text-center ml-0 mt-0 mb-0 white--text"
                  style="font-size:45px; text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;"
                 v-if="system_type_run === 'UAT'"
                >
                  ONE BOX UAT
                </h2>
                <h2
                  class="text-center ml-0 mt-0 mb-0 white--text"
                  style="font-size:45px; text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;"
                  v-else
                >
                  ONE BOX
                </h2>
                <h2
                  class="text-center ml-0 mb-0 white--text"
                  style="font-size:21px; text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;"
                >
                  Human Trusted IT Service
                </h2>
                <v-row class="fill-height white--text" justify="center">
                  <v-col cols="12">
                    <v-container grids-list-xs>
                      <img
                        v-if="resolutionScreen >= 400"
                        src="@/assets/landinglogo2.png"
                        width="450px"
                      />
                      <img
                        v-else
                        src="@/assets/landinglogo2.png"
                        width="300px"
                      />
                    </v-container>
                  </v-col>
                </v-row>
              </v-col>
              <!-- test -->

              <v-col
                v-if="check_easylogin == false"
                md="6"
                class="fill-height pa-4"
                align="center"
                justify="center"
              >
              <!-- เวอร์ชั่นเก่า -->
              <h2
                  class="mt-0 mb-0 white--text"
                  style="font-size:36px;text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;"
                >
                  {{ $t("landingpagenavbar.login") }}
                </h2>
              <!-- เวอร์ชั่นใหม่ -->
              <!-- <v-layout style="justify-content: center;">
                <u style="color:white;">
                <h2
                  class="mt-0 mb-0 white--text"
                  style="font-size:36px;text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;cursor:pointer;"
                  @click="check_easylogin = false"
                >
                  {{ $t("landingpagenavbar.login") }}
                </h2>
                </u>
                <h2
                class="ml-5 mr-0"
                style="font-size:36px; color:white;text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;"
                >|
                </h2>
                <v-btn
                  class="mt-2" 
                  text 
                  large
                  rounded
                >
                <h2
                    class="mt-0"
                    style="font-size:28px; color:white;text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px; cursor:pointer;"
                    @click="check_easylogin = true"
                  >
                  ลงทะเบียน
                </h2>
                </v-btn>
              </v-layout> -->
                <br />
                <v-alert
                  v-if="countDownFailedLogin > 0"
                  type="error"
                >
                  {{ $t("landingpage.failedLogin") + convertTime }}
                </v-alert>
                <v-tabs
                  show-arrows
                  fixed-tabs
                  background-color="teal darken-3"
                  dark
                  v-model="step1"
                >
                  <!-- login username -->
                  <v-tab @click="tab_username = true, tab_idcard = false, tab_sms = false, $v.$reset()">
                    <v-icon class="mr-1">mdi-account-box</v-icon>
                    Username
                  </v-tab>
                  <!-- login หมอพร้อม -->
                  <v-tab @click="tab_username = false, tab_idcard = true, tab_sms = false, $v.$reset()">
                    <v-icon class="mr-1">mdi-account-box</v-icon>
                    ID Card
                  </v-tab>
                  <!-- login SMS -->
                  <v-tab @click="tab_username = false, tab_idcard = false, tab_sms = true, $v.$reset()">
                    <v-icon class="mr-1">mdi-cellphone</v-icon>
                    SMS
                  </v-tab>
                </v-tabs>
                <!-- login username -->
                <div v-if="tab_username">
                  <v-card-text class="text-left" style="color:white;">
                    <b>{{ $t("username") }}</b>
                    <v-text-field
                      class="mt-2"
                      outlined
                      solo
                      flat
                      color="#174966"
                      prepend-inner-icon="account_box"
                      dense
                      :disabled="loading || opendialog2faotp"
                      v-model.trim="username"
                      :error-messages="usernameloginError"
                      @input="fn_tolowercase(), $v.username.$touch()"
                      @blur="$v.username.$touch()"
                    ></v-text-field>
                    <b>{{ $t("password") }}</b>
                    <v-text-field
                      class="mt-2"
                      prepend-inner-icon="lock"
                      outlined
                      solo
                      color="#174966"
                      flat
                      dense
                      :disabled="loading || opendialog2faotp"
                      v-model.trim="password"
                      @click:append="showpassword = !showpassword"
                      :type="showpassword ? 'text' : 'password'"
                      :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :error-messages="passwordloginError"
                      @input="fn_tolowercase(), $v.password.$touch()"
                      @blur="$v.password.$touch()"
                    ></v-text-field>
                  </v-card-text>
                  <v-layout justify-space-between>
                    <v-checkbox
                      v-model="check_memory"
                      color="primary"
                      class="text-center"
                      style="margin-left:6px;"
                    >
                      <template v-slot:label>
                        <v-card-text
                          class="pa-0 pt-0"
                          style="font-size:14px"
                          @click.stop=""
                        >
                          <!-- จดจำข้อมูลผู้ใช้งาน -->
                          {{ $t("landingpage.rememberme") }}
                        </v-card-text>
                      </template>
                    </v-checkbox>
                    <a
                      :href="urloneid + '/type_forgot_password'"
                      style="text-decoration: none; align-self:center; font-size:14px;margin-right:18px;"
                      target="_blank"
                      ><u>{{ $t("forgotpassword") }}</u></a
                    >
                  </v-layout>
                  <v-layout justify-left class="pt-0" style="margin-left:6px">
                    <v-checkbox
                      v-model="check_button_login"
                      color="primary"
                      class="text-center"
                    >
                      <template v-slot:label>
                        <!-- <v-card-text class="pa-0 pt-0" style="font-size:14px" @click.stop="">
                              ยอมรับ
                              <a @click.prevent="open()">ข้อกำหนดการใช้บริการ ONE ID</a>
                              และ
                              <a @click.prevent="opendialogpolicyoneid = true">นโยบายความคุ้มครองข้อมูลส่วนบุคคล</a><br>
                              <p>(Accept Terms Of Service ONE ID And Privacy Policy)</p>
                            </v-card-text> -->
                        <v-card-text class="pa-0 pt-0" style="font-size:14px;margin-right:32px;margin-bottom:0px" @click.stop="">
                          {{ $t('landingpage.accept') }}
                          <a @click.prevent="open()">{{ $t('landingpage.termsofserviceoneid') }}</a>
                          {{ $t('landingpage.and') }}
                          <a @click.prevent="opendialogpolicyoneid = true">{{ $t('landingpage.privacypolicy') }}</a>
                        </v-card-text>
                      </template>
                    </v-checkbox>
                  </v-layout>
                  <v-col class="col-md-6 col-sm-6">
                    <v-btn 
                      @click="checkAccount"
                      rounded
                      :disabled="!check_button_login || countDownFailedLogin > 0"
                      block
                      style="font-size:16px;font-weight:600; background-color:#FFFFFF; color:#005799; text-shadow: rgba(0, 0, 0, 0.25)"
                      :loading="loading"
                    >
                        <!-- เข้าสู่ระบบ -->
                        {{ $t('landingpagenavbar.login') }}
                    </v-btn>
                  </v-col>
                  <!-- ONE COLLABORATION MAIL -->
                  <!-- <v-card-text class="text-center">
                    <span>หรือ</span>
                  </v-card-text>
                  <v-card-text class="text-left">
                    <span>Login with</span>
                  </v-card-text>
                  <v-btn 
                    block
                    style="font-size:16px;font-weight:600; background-color:#154966; color:#FFFFFF; text-shadow: rgba(0, 0, 0, 0.25)"
                    >
                    <img src="/img/inetlogo-mail.png" width="50px">
                    <v-divider vertical></v-divider>
                  ONE COLLABORATION MAIL
                  </v-btn> -->                  
                  <v-card-text class="text-center">
                    <span>
                      <!-- สร้างบัญชี ONE-ID ? -->
                      {{ $t('landingpage.createaccount_oneid') }}
                    <a 
                    @click="check_easylogin = true"
                    style="text-decoration: none;" 
                    target="_blank"
                    >
                    <!-- ลงทะเบียน Easy One ID -->
                    {{$t('landingpage.registereasyoneid') }}
                    </a>
                    </span>
                  </v-card-text>

                  <!-- ปุ่มเข้าสู่ระบบ Onecollab -->
                  <!-- <v-col>
                    <v-btn 
                      class="px-3"
                      @click="login_one_collab()"
                      rounded
                      style="font-size:16px;font-weight:600; background-color:#005799; color:#FFFFFF; text-shadow: rgba(0, 0, 0, 0.25)"
                      :loading="loading"
                      >
                      <img
                        :src="require('@/assets/img/inetlogo-mail.png')"
                      >
                      |
                        {{ $t('landingpagenavbar.login_onecollab') }}
                    </v-btn>
                  </v-col> -->
                </div>

                <!-- login หมอพร้อม -->
                <div v-if="tab_idcard">
                  <v-card-text 
                    class="text-left"
                    style="color:white;"
                  >
                    <b>{{ $t("idcard") }}</b>
                    <v-text-field 
                      class="mt-2"
                      outlined
                      solo
                      flat
                      color="#174966"
                      prepend-inner-icon="credit_card"
                      dense
                      :disabled="loading"
                      v-model.trim="idcard"
                      :error-messages="idcardloginError"
                      @input="fn_tolowercase(), $v.idcard.$touch()"
                      @blur="$v.idcard.$touch()"
                    ></v-text-field>
                    <b>{{ $t("password") }}</b>
                    <v-text-field
                      class="mt-2"
                      prepend-inner-icon="lock"
                      outlined
                      solo
                      color="#174966"
                      flat
                      dense
                      :disabled="loading"
                      v-model.trim="password"
                      @click:append="showpassword = !showpassword"
                      :type="showpassword ? 'text' : 'password'"
                      :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :error-messages="passwordloginError"
                      @input="fn_tolowercase(), $v.password.$touch()"
                      @blur="$v.password.$touch()"
                    ></v-text-field>            
                  </v-card-text>
                  <v-layout justify-space-between>
                    <v-checkbox 
                    v-model="check_memory" 
                    color="primary" 
                    class="text-center"
                    style="margin-left:6px;"
                    >
                      <template v-slot:label>
                        <v-card-text class="pa-0 pt-0 " style="font-size:14px" @click.stop="">
                          {{ $t('landingpage.rememberme') }}
                        </v-card-text>
                      </template>
                    </v-checkbox>                                
                    <a
                      :href="urloneid + '/type_forgot_password'"
                      style="text-decoration: none; align-self:center; font-size:14px;margin-right:18px;"
                      target="_blank"
                      ><u>{{ $t("forgotpassword") }}</u></a
                    >
                  </v-layout>
                  <v-layout justify-left class="pt-0" style="margin-left:6px">
                    <v-checkbox
                      v-model="check_button_login"
                      color="primary"
                      class="text-center"
                    >
                      <template v-slot:label>
                        <v-card-text
                          class="pa-0 pt-0"
                          style="font-size:14px;margin-right:32px;margin-bottom:0px"
                          @click.stop=""
                        >
                          {{ $t("landingpage.accept") }}
                          <a @click.prevent="open()">{{
                            $t("landingpage.termsofserviceoneid")
                          }}</a>
                          {{ $t("landingpage.and") }}
                          <a @click.prevent="opendialogpolicyoneid = true">{{
                            $t("landingpage.privacypolicy")
                          }}</a>
                        </v-card-text>
                      </template>
                    </v-checkbox>
                  </v-layout>
                  <v-col class="col-md-6 col-sm-6">
                    <v-btn
                      @click="login"
                      rounded
                      :disabled="!check_button_login"
                      block
                      style="font-size:16px;font-weight:600; background-color:#FFFFFF; color:#005799; text-shadow: rgba(0, 0, 0, 0.25)"
                      :loading="loading"
                    >
                      {{ $t("landingpagenavbar.login") }}
                    </v-btn>
                  </v-col>
                  <v-card-text class="text-center">
                    <span>
                      {{ $t("landingpage.createaccount_oneid") }}
                      <a
                        @click="fn_register_mohpromt"
                        style="text-decoration: none;"
                        target="_blank"
                      >
                        {{ $t("landingpagenavbar.registerone") }}
                      </a>
                    </span>
                  </v-card-text>
                </div>

                <!-- login SMS -->
                <div v-if="tab_sms">
                  <div v-if="slideVerify">
                    <v-card-text class="text-left" style="color:white;">
                      <b>{{ $t("loginSMS") }}</b>
                      <v-text-field
                        class="mt-2"
                        outlined
                        solo
                        hide-details="auto"
                        flat
                        color="#174966"
                        prepend-inner-icon="phone"
                        dense
                        :disabled="loading"
                        :label="$t('phoneNumber')"
                        v-model.trim="phonenumber"
                        maxlength="10"
                        :error-messages="phonenumberError"
                        @input="fn_tolowercase(), $v.phonenumber.$touch()"
                        @blur="$v.phonenumber.$touch()"
                        :oninput="regexphone"
                      ></v-text-field>
                    </v-card-text>
                    <v-layout row wrap justify-left style="margin-left:6px">
                      <v-checkbox
                        v-model="check_button_login"
                        color="primary"
                        class="text-center"
                        style="size:1px"
                      >
                        <template v-slot:label>
                          <v-card-text
                            class="pa-0 pt-0"
                            style="font-size:14px"
                            @click.stop=""
                          >
                            <!-- ยอมรับ -->
                            {{ $t("landingpage.accept") }}
                            <a @click.prevent="open()">
                              <!-- ข้อกำหนดการใช้บริการ ONE ID -->
                              {{ $t("landingpage.termsofserviceoneid") }}
                            </a>
                            <!-- และ -->
                            {{ $t("landingpage.and") }}
                            <a @click.prevent="opendialogpolicyoneid = true">
                              <!-- นโยบายความคุ้มครองข้อมูลส่วนบุคคล -->
                              {{ $t("landingpage.privacypolicy") }}
                            </a>
                            <!-- <p>(Accept Terms Of Service ONE ID And Privacy Policy)</p> -->
                          </v-card-text>
                        </template>
                      </v-checkbox>
                    </v-layout>
                    <drag-verify
                      class="mb-5"
                      v-model="modelValue"
                      style="height: 45px; width: 330px;"
                      :disabled="!check_button_login || countDownFailedLogin > 0"
                      :text="$t('otp')"
                      @successFunction="sendOTP()"
                    >
                    </drag-verify>
                    <!-- <drag-verify
                      :disabled="!check_button_login"
                      width="350"
                      height="50"
                      text="เลื่อนไปทางขวาเพื่อรับ OTP"
                      :success-text="successText"
                      background="linear-gradient(#4CACBC, #5AB6B0)"
                      progress-bar-bg="linear-gradient(#64ba80, #77CAAD)"
                      completed-bg="linear-gradient(#64ba80, #77CAAD)"
                      handler-bg="#fff"
                      text-size="16px"
                      success-icon="mdi-check-bold"
                      handler-icon="mdi-check"
                    ></drag-verify> -->
                    <!-- <div class="center-xy sildeOTP">
                      <h1>Slide to Unlock</h1>
                      <input type="range" value="0" max="100" class="pullee" height="50" />
                    </div> -->
                  </div>
                  <div v-else>
                    <h3 class="text-center mt-4 mb-6">{{ $t("verifyOTP") }}</h3>
                    <div class="ma-auto position-relative" style="max-width: 100%">
                      <otp-input
                        :disabled="loading"
                        :digits="6"
                        @on-complete="onCompleteOTP"
                        @on-changed="onChangeOTP" 
                        separateInputClass="separate-input-class" 
                        placeholder=""
                      ></otp-input>
                      <!-- <v-text-field
                        v-model="otp"
                        class="mt-2"
                        outlined
                        solo
                        hide-details="auto"
                        flat
                        color="#174966"
                        dense
                        :disabled="loading"
                        :label="$t('enterOTP')"
                        maxlength="6"
                      ></v-text-field> -->
                    </div>
                    <v-layout row wrap justify-center class="px-7 py-4">
                      <v-flex lg4 class="d-flex justify-start align-center">
                        <div>{{ $t("countDown") }}{{ formattedCountdownLoginSMS }}</div>
                      </v-flex>
                      <v-flex lg4 class="d-flex justify-end">
                        <v-btn
                          text
                          color="primary"
                          style="text-decoration: underline;"
                          :disabled="!isActiveResendOTP"
                          @click="isActiveResendOTP = false, sendOTP()"
                        >
                          {{ $t("resendOTP") }}
                        </v-btn>
                      </v-flex>
                    </v-layout>
                    <v-btn 
                      rounded 
                      class="px-8 my-2" 
                      :disabled="!isActiveConfirmOTP || countDown === 0 || countDownFailedLogin > 0"
                      @click="login"
                      :loading="loading"
                    >
                      {{ $t('confirmOTP') }}
                    </v-btn>
                  </div>
                </div>
                <!-- One Collaboration -->
                    <div class="text-center">
                      <v-dialog
                        v-model="opendialogonecollab"
                        width="500"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn 
                            v-bind="attrs"
                            v-on="on"
                            class="px-3"
                            rounded
                            style="font-size:16px;font-weight:600; background-color:#005799; color:#FFFFFF; text-shadow: rgba(0, 0, 0, 0.25)"
                            >
                            <img
                              :src="require('@/assets/img/inetlogo-mail.png')"
                            >
                            |
                              <!-- เข้าสู่ระบบ One Collaboration Mail -->
                              {{ $t('landingpagenavbar.login_onecollab') }}
                          </v-btn>
                        </template>

                        <v-card>
                          
                          <v-card-title class="text-h5" style="background-color:#005799; color:#FFFFFF; justify-content: space-around;">
                            <img :src="require('@/assets/img/inetlogo-mail.png')">
                           <p style="font-size:20px;margin-bottom:0px;margin-left:-5px"> Login with One Collaboration Mail</p>
                              <v-btn
                              style="margin-right:-20px;color:#FFFFFF;"
                              fab 
                              icon 
                              small
                              @click="(opendialogonecollab = false), (tab_one_collab = false)"
                              >
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                          </v-card-title>

                          <v-card-text
                            class="text-left mt-3" style="color:#33333;">
                            <b>{{ $t("email") }}</b>
                            <v-text-field
                              class="mt-2"
                              outlined
                              solo
                              hide-details
                              flat
                              color="#174966"
                              prepend-inner-icon="mdi-email"
                              dense
                              :disabled="loading"
                              v-model.trim="email_one_collab"
                              @input="fn_tolowercase()"
                            ></v-text-field>
                            <br />
                            <b>{{ $t("password") }}</b>
                            <v-text-field
                              class="mt-2"
                              prepend-inner-icon="lock"
                              outlined
                              solo
                              color="#174966"
                              flat
                              dense
                              hide-details
                              :disabled="loading"
                              v-model.trim="password_one_collab"
                              @click:append="showpassword_one_collab = !showpassword_one_collab"
                              :type="showpassword_one_collab ? 'text' : 'password'"
                              :append-icon="showpassword_one_collab ? 'mdi-eye' : 'mdi-eye-off'"
                            ></v-text-field>
                          </v-card-text>
                        
                          <v-col style="text-align-last: center;">
                            <v-btn 
                              class="px-5 mb-3"
                              @click="tab_one_collab = true, login()"
                              rounded                       
                              style="font-size:16px;font-weight:600; background-color:#005799; color:#FFFFFF; text-shadow: rgba(0, 0, 0, 0.25)"
                              :loading="loading"                              
                            >
                                <!-- เข้าสู่ระบบ -->
                                {{ $t('landingpagenavbar.login') }}
                            </v-btn>
                          </v-col>

                          <v-divider></v-divider>
                        </v-card>
                      </v-dialog>
                    </div>
                    <!-- login with azure id -->
                    <div class="pa-3">
                      <v-btn
                        @click="loginAzureID"
                        rounded
                        class="px-13"
                        style="font-size:16px; font-weight:600; background-color:#FFFFFF; color:#005799; text-shadow: rgba(0, 0, 0, 0.25)"
                        :loading="loading_azure"
                        >
                          {{ $t('landingpagenavbar.login_azure_id') }}
                      </v-btn>
                    </div>
              </v-col>

              <v-col
                v-else
                md="6"
                class="fill-height pa-4"
                align="center"
                justify="center"
              >
                  <!-- REGISTER -->
                  <!-- เวอร์ชั่นเก่า -->
                <h2
                  class="mt-1 mb-10 white--text"
                  style="font-size:36px;text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;"
                >
                  {{ $t("landingpage.registeruserheader") }}
                </h2>
                <!-- เวอร์ชั่นใหม่ -->
                <!-- <v-layout justify-center class="mb-4">
                <v-btn
                  class="mt-2" 
                  text 
                  large
                  rounded
                >
                <h2
                  class="mt-0 mb-0 white--text"
                  style="font-size:28px;text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;cursor:pointer;"
                  @click="check_easylogin = false"
                >
                  {{ $t("landingpagenavbar.login") }}
                </h2>
                </v-btn>
                <h2
                class="ml-0 mr-5"
                style="font-size:36px; color:white;text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;"
                >|
                </h2>
                <u style="color:white;">
                <h2
                    class="mb-2"
                    style="font-size:36px; color:white;text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px; cursor:pointer;"
                    @click="check_easylogin = true"
                  >
                  ลงทะเบียน
                </h2>
                </u>
                </v-layout> -->
                <v-stepper
                  dark
                  style="background: #00695C;"
                  v-model="e1"
                  class="mb-5 ml-9"
                >
                  <v-stepper-header>
                    <v-stepper-step :complete="e1 > 1" step="1">
                      <!-- ลงทะเบียนผู้ใช้งาน -->
                      {{ $t("landingpage.registeruserheader") }}
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="2">
                      <!-- เสร็จสิ้น -->
                      {{ $t("landingpage.success") }}
                    </v-stepper-step>
                  </v-stepper-header>
                </v-stepper>
                <div v-if="e1 == 1">
                  <!-- label="เบอร์โทรศัพท์ / Mobile No." -->
                  <v-text-field
                    clearable
                    solo
                    outlined
                    dense
                    class="pa-1 pt-0 pb-0"
                    v-model="phonenumber"
                    :label="$t('landingpage.mobileno')"
                    maxlength="10"
                    prepend-icon="phone"
                    :rules="requiredPhoneNumber"
                    required
                    :error-messages="phonenumberError"
                    @input="$v.phonenumber.$touch()"
                    @blur="$v.phonenumber.$touch()"
                    tabindex="1"
                  ></v-text-field>
                  <!-- label="ชื่อผู้ใช้ / Username" -->
                  <v-text-field
                    clearable
                    solo
                    outlined
                    dense
                    class="pa-1 pt-0 pb-0"
                    v-model="username_register"
                    :label="$t('landingpage.username')"
                    prepend-icon="people"
                    :rules="requiredusername"
                    required
                    :error-messages="usernameError"
                    @input="fn_tolowercase(), $v.username_register.$touch()"
                    @blur="$v.username_register.$touch()"
                    tabindex="2"
                  ></v-text-field>
                  <!-- label="รหัสผ่าน / Password" -->
                  <v-text-field
                    clearable
                    solo
                    outlined
                    dense
                    class="pa-1 pt-0 pb-0"
                    v-model="password_register"
                    :label="$t('landingpage.password')"
                    prepend-icon="vpn_key"
                    @click:append="
                      showpassword_register = !showpassword_register
                    "
                    :type="showpassword_register ? 'text' : 'password'"
                    :append-icon="
                      showpassword_register ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    :rules="requiredpassword"
                    :error-messages="passwordError"
                    required
                    @input="$v.password_register.$touch()"
                    @blur="$v.password_register.$touch()"
                    tabindex="3"
                  ></v-text-field>
                  <!-- label="ยืนยันรหัสผ่าน / ConfirmPassword" -->
                  <v-text-field
                    clearable
                    solo
                    outlined
                    dense
                    class="pa-1 pt-0 pb-0"
                    v-model="confirmpassword_register"
                    :label="$t('landingpage.confirmpassword')"
                    prepend-icon="vpn_key"
                    @click:append="
                      showconfirmpassword_register = !showconfirmpassword_register
                    "
                    :type="showconfirmpassword_register ? 'text' : 'password'"
                    :append-icon="
                      showconfirmpassword_register ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    :rules="requiredconfirmpassword"
                    :error-messages="confirmpasswordError"
                    required
                    @input="$v.confirmpassword_register.$touch()"
                    @blur="$v.confirmpassword_register.$touch()"
                    tabindex="4"
                  ></v-text-field>
                  <v-layout row wrap justify-center>
                    <v-checkbox
                      v-model="check_accept"
                      color="primary"
                      class="text-center ml-4"
                      tabindex="5"
                    >
                      <template v-slot:label>
                        <v-card-text
                          class="pa-0 pt-0"
                          style="font-size:14px;"
                          @click.stop=""
                        >
                          <!-- ยอมรับ -->
                          {{ $t("landingpage.accept") }}
                          <a @click.prevent="open()">
                            <!-- ข้อกำหนดการใช้บริการ ONE ID -->
                            {{ $t("landingpage.termsofserviceoneid") }}
                          </a>
                          <!-- และ -->
                          {{ $t("landingpage.and") }}
                          <a @click.prevent="opendialogpolicyoneid = true">
                            <!-- นโยบายความคุ้มครองข้อมูลส่วนบุคคล -->
                            {{ $t("landingpage.privacypolicy") }}
                          </a>
                          <!-- <p>(Accept Terms Of Service ONE ID And Privacy Policy)</p> -->
                        </v-card-text>
                      </template>
                    </v-checkbox>
                  </v-layout>
                  <v-col class="col-md-6 col-sm-6">
                    <v-btn
                      :loading="createprogress"
                      :disabled="!check_accept"
                      @click="fn_register()"
                      rounded
                      block
                      style="font-size:16px;font-weight:600; background-color:#FFFFFF; color:#005799; text-shadow: rgba(0, 0, 0, 0.25)"
                    >
                      <!-- ลงทะเบียน -->
                      {{ $t("landingpage.registerbtn") }}
                    </v-btn>
                  </v-col>
                  <v-card-text class="text-center">
                    <span>
                      <!-- คุณมีบัญชี One Platform ใช่หรือไม่ ? -->
                      {{ $t("landingpage.haveanaccount") }}
                      <a
                        @click="check_easylogin = false"
                        style="text-decoration: none;"
                        target="_blank"
                      >
                        <!-- ลงชื่อเข้าใช้งาน -->
                        {{ $t("landingpage.loginlink") }}
                      </a>
                    </span>
                  </v-card-text>
                </div>
                <div v-else>
                  <v-card class="ml-9" style="background: #E3F2FD;">
                    <v-card-text>
                      <v-layout row wrap justify-center>
                        <v-flex xs12 sm12 md12 lg12 class="text-center">
                          <v-icon size="50px" color="primary"
                            >mdi-check-circle-outline</v-icon
                          >
                          <br /><br />
                          <!-- <h2 style="font-size:18px">ลงทะเบียนสำเร็จ</h2><br>
                            <h3 style="color:#00C853;">( Register Easy OneID  Successfully )</h3> -->
                          <h2 style="font-size:18px">
                            {{ $t("landingpage.registersuccessfully") }}
                          </h2>
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap justify-center>
                        <v-flex xs6 sm6 md8 lg6>
                          <v-card
                            lass="mx-auto"
                            max-width="300"
                            max-height="200"
                            elevation="0"
                            style="background: #E3F2FD;"
                          >
                            <!-- <v-card-text>       
                        <p style="text-align: center; color:red;">โปรดจำข้อมูลนี้ !!</p>
                        <p style="padding-left: 30px; text-align: left;"><strong>username :</strong> {{ username_register }}</p>
                        <p style="padding-left: 30px; text-align: left;"><strong>อีเมล :</strong> {{ email }}</p>
                        <p style="padding-left: 30px; text-align: left;"><strong>เบอร์โทร :</strong> {{ phonenumber }}</p>
                      </v-card-text> -->
                            <v-card-text>
                              <p style="text-align: center; color:red;">
                                {{ $t("landingpage.pleaseremember") }}
                              </p>
                              <p style="padding-left: 30px; text-align: left;">
                                <strong
                                  >{{ $t("landingpage.username") }} :</strong
                                >
                                {{ username_register }}
                              </p>
                              <!-- <p style="padding-left: 30px; text-align: left;"><strong>อีเมล :</strong> {{ email }}</p> -->
                              <p style="padding-left: 30px; text-align: left;">
                                <strong
                                  >{{ $t("landingpage.mobileno") }} :</strong
                                >
                                {{ phonenumber }}
                              </p>
                            </v-card-text>
                            <br />
                          </v-card>
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap justify-center class="mt-0">
                        <v-flex class="text-center">
                          <v-btn
                            rounded
                            large
                            class="ml-2"
                            color="primary"
                            @click="
                              (check_easylogin = false),
                                (e1 = 1),
                                fn_cleardata()
                            "
                          >
                            <!-- เข้าสู่ระบบ
                      <br>(Log in) -->
                            {{ $t("landingpagenavbar.login") }}
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-else>
            <v-row no-gutters>
              <v-col class="text-center pa-0" cols="12" md="6">
                <h2
                  class="text-center ml-0 mt-0 mb-0 white--text"
                  style="font-size:45px; text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;"
                >
                  ONE BOX
                </h2>
                <h2
                  class="text-center ml-0 mb-0 white--text"
                  style="font-size:21px; text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;"
                >
                  Human Trusted IT Service
                </h2>
                <v-row class="fill-height white--text" justify="center">
                  <v-col cols="12">
                    <v-container grids-list-xs>
                      <img
                        v-if="resolutionScreen >= 400"
                        src="/img/landinglogo.d3c30f3c.png"
                        width="450px"
                      />
                      <img
                        v-else
                        src="/img/landinglogo.d3c30f3c.png"
                        width="300px"
                      />
                    </v-container>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                md="6"
                class="pa-4 pt-10 mt-10"
                align="center"
                justify="center"
              >
                <h2
                  class="mt-10 mb-10 white--text"
                  style="font-size:36px;text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;"
                >
                  <!-- Log in -->
                  {{ $t("landingpagenavbar.login") }}
                </h2>
                <br />

                <div>
                  <v-card-text class="text-center" style="color:white;">
                    <h2>{{ $t("username") }} : {{ username_show }}</h2>
                    <!-- <v-text-field 
                    class="mt-2"
                    outlined
                    solo
                    hide-details
                    flat
                    color="#174966"
                    prepend-inner-icon="account_box"
                    dense
                    disabled
                    v-model="username_show"
                  ></v-text-field> -->
                    <br />
                  </v-card-text>
                  <!-- background-color:#005799; -->
                  <v-col class="col-md-12 col-sm-12">
                    <v-btn
                      @click="checkAccount"
                      rounded
                      style="font-size:16px;font-weight:600; background-color:#FFFFFF; color:#005799; text-shadow: rgba(0, 0, 0, 0.25)"
                      :loading="loading"
                    >
                      <!-- เข้าสู่ One Box -->
                      {{ $t("landingpagenavbar.gotoonebox") }}
                    </v-btn>
                    <span v-if="resolutionScreen < 400"><br /><br /></span>
                    <v-btn
                      @click="checkLogoutAccount"
                      rounded
                      class="ml-2"
                      style="font-size:16px;font-weight:600; background-color:#FFFFFF; color:#005799; text-shadow: rgba(0, 0, 0, 0.25)"
                    >
                      <!-- ออกจากระบบ -->
                      {{ $t("landingpagenavbar.logout") }}
                    </v-btn>
                  </v-col>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <dialogoneid
            :show="opendialogoneid"
            @closedialog="opendialogoneid = false"
          ></dialogoneid>
          <dialogpolicyoneid
            :show="opendialogpolicyoneid"
            @closedialog="opendialogpolicyoneid = false"
          ></dialogpolicyoneid>
        </section>
      </div>

      <!-- ขนาดหน้าจอโทรศัพท์ -->
      <div v-else>
        <section
          style="background: #E3F2FD;
        background: linear-gradient(to bottom, #4CACBC, #6CC4A1, #B2EBF2, #8dbff2);"
          fixed-tabs
        >
          <v-container v-if="checkbox_remember == false">
            <v-row no-gutters justify="center">
              <v-col class="text-center" cols="12" md="6">
                <v-row class="fill-height white--text" justify="center">
                  <v-col cols="12">
                    <!-- <v-layout justify-center>
                      <img
                        style="margin-top:-30px"
                        src="@/assets/logo_notebook.png"
                        width="300px"
                      />
                    </v-layout> -->
                    <v-layout justify-center>
                      <img
                        style="margin-top:-35px;margin-right:85px"
                        :src="
                          require('@/assets/img/logo_onebox-horizontal-white.png')
                        "
                        width="140px"
                      />
                    </v-layout>
                    <v-layout justify-center>
                      <p
                        class="ml-0 mb-0 white--text"
                        style="font-size:18px;margin-right:-100px;margin-top:-25px"
                      >
                        Human Trusted IT Service
                      </p>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-col>

              <!--หน้าต่างกรอกเข้าสู่ระบบ/สมัครสมาชิกแบบง่าย-->
              <v-card class="mt-2" style="border-radius:10px">
                <v-col
                  v-if="check_easylogin == false"
                  md="6"
                  class="fill-height pa-4"
                  align="center"
                  justify="center"
                >
                  <v-layout justify-center>
                    <v-icon class="mr-2" style="color:#184966">mdi-key</v-icon>
                    <h2 style="font-size:18px;color:#184966">
                      {{ $t("landingpagenavbar.login") }}
                    </h2>
                  </v-layout>
                  <br />

                  <!-- <v-layout>
                <v-col>
              <v-sheet
                class="mx-auto"
                height="40"
                width="300"
              >
                <v-slide-group
                v-model="step1"
                mandatory
                >
                  <v-slide-item
                    v-for="n in 3"
                    :key="n"
                    v-slot="{ active, toggle }"
                  >
                    <v-btn
                      class="mx-3"
                      :input-value="active"
                      :color="active ? 'btn-active' : '#F7F7F7'"
                      depressed
                      rounded
                      @click="toggle"
                    >
                      Option {{ n }}
                    </v-btn>
                  </v-slide-item>
                </v-slide-group>
              </v-sheet>
              </v-col>
              </v-layout>
              <h1>{{step1}}</h1> -->
              <v-alert
                v-if="countDownFailedLogin > 0"
                type="error"
              >
                {{ $t("landingpage.failedLogin") + convertTime }}
              </v-alert>
              <v-flex xs12 row wrap justify-center class="mb-2">
                <v-sheet
                  class="rounded-pill" 
                  outlined color="#71B5D8" 
                  rounded
                  width=""
                  style="padding:0px;"
                >
                  <v-card
                    class="rounded-pill"
                    height="35"
                    outlined color="#F5F5F5"
                    style="padding-right:0pxpx;"
                  >
                    <!-- <v-card-text
                    justify-center
                    style="margin-top:-23.5px;paddging-right:0px"> -->
                      <v-chip-group
                        center-active
                        fixed-tabs
                        active-class="white--text"
                        v-model="step1"
                        mandatory
                        style="margin-top:-7.5px;margin-left:0px;"
                      >
                        <v-chip
                          class="mr-0 black--text"
                          style="background-color:#F5F5F5;"
                          :color="active ? '#F7F7F7' : 'btn-active'"
                          @click="tab_username = true, tab_idcard = false, tab_sms = false, $v.$reset()"
                        >
                          <v-icon class="mr-1">mdi-account-box</v-icon>
                            Username
                        </v-chip>

                        <v-chip
                          class="mr-0 black--text"
                          style="background-color:#F5F5F5;margin-left:7px;"
                          :color="active ? '#F7F7F7' : 'btn-active'"
                          @click="tab_username = false, tab_idcard = true, tab_sms = false, $v.$reset()"
                        >
                          <v-icon class="mr-1">mdi-account-box</v-icon>
                            ID Card
                        </v-chip>

                        <v-chip
                          class="mr-0 black--text"
                          style="background-color:#F5F5F5;margin-left:7px;"
                          :color="active ? '#F7F7F7' : 'btn-active'"
                          @click="tab_username = false, tab_idcard = false, tab_sms = true, $v.$reset()"
                        >
                          <v-icon class="mr-1">mdi-cellphone</v-icon>
                            SMS
                        </v-chip>
                      </v-chip-group>
                    <!-- </v-card-text> -->
                    </v-card>
                </v-sheet>
              </v-flex>
                  <!-- แบบเก่า V-tabs -->
                  <!-- <v-tabs
                    style="border-radius:50px;border-color:red"
                    show-arrows
                    fixed-tabs
                    background-color="#989898"
                    dark
                    v-model="step1"
                  > -->
                    <!-- <v-tab
                      @click="
                        (tab_sms = false),
                          (tab_idcard = false)
                      "
                    >
                      <v-icon class="mr-1">mdi-account-box</v-icon>
                      Username
                    </v-tab> -->
                    <!-- หมอพร้อม -->
                    <!-- <v-tab @click="tab_idcard = true">
                      <v-icon class="mr-1">mdi-account-box</v-icon>
                      ID Card
                    </v-tab> -->
                    <!-- tab_sms = true  -->
                    <!-- <v-tab @click="$router.push('/loginsms')">
                      <v-icon class="mr-1">mdi-cellphone</v-icon>
                      SMS
                    </v-tab> -->
                  <!-- </v-tabs> -->

                  <!-- login username -->
                  <div v-if="tab_username">
                    <v-card-text class="text-left" style="color:#333333;">
                      <b>{{ $t("username") }}</b>
                      <v-text-field
                        :label="$t('username')"
                        class="mt-2"
                        outlined
                        solo
                        flat
                        color="#174966"
                        prepend-inner-icon="account_box"
                        dense
                        :disabled="loading"
                        v-model.trim="username"
                        :error-messages="usernameloginError"
                        @input="fn_tolowercase(), $v.username.$touch()"
                        @blur="$v.username.$touch()"
                      ></v-text-field>
                      <b>{{ $t("password") }}</b>
                      <v-text-field
                        :label="$t('password')"
                        class="mt-2"
                        prepend-inner-icon="lock"
                        outlined
                        solo
                        color="#174966"
                        flat
                        dense
                        :disabled="loading"
                        v-model.trim="password"
                        @click:append="showpassword = !showpassword"
                        :type="showpassword ? 'text' : 'password'"
                        :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :error-messages="passwordloginError"
                        @input="fn_tolowercase(), $v.password.$touch()"
                        @blur="$v.password.$touch()"
                      ></v-text-field>
                    </v-card-text>
                    <v-flex xs11>
                      <v-layout justify-space-between>
                        <v-checkbox v-model="check_memory" color="primary">
                          <template v-slot:label>
                            <v-card-text
                              class="pa-0 pt-0 "
                              style="font-size:14px"
                              @click.stop=""
                            >
                              <!-- จดจำข้อมูลผู้ใช้งาน -->
                              {{ $t("landingpage.rememberme") }}
                            </v-card-text>
                          </template>
                        </v-checkbox>

                        <a
                          :href="urloneid + '/type_forgot_password'"
                          style="text-decoration: none; align-self:center; font-size:14px;"
                          target="_blank"
                          ><u>{{ $t("forgotpassword") }}</u></a
                        >
                      </v-layout>

                      <v-layout row wrap justify-center class="pt-0 mb-2">
                        <v-checkbox
                          v-model="check_button_login"
                          color="primary"
                          class="text-center"
                          style="margin-left:13px; margin-top:-10px"
                        >
                          <template v-slot:label>
                            <!-- <v-card-text class="pa-0 pt-0" style="font-size:14px" @click.stop="">
                                ยอมรับ
                                <a @click.prevent="open()">ข้อกำหนดการใช้บริการ ONE ID</a>
                                และ
                                <a @click.prevent="opendialogpolicyoneid = true">นโยบายความคุ้มครองข้อมูลส่วนบุคคล</a><br>
                                <p>(Accept Terms Of Service ONE ID And Privacy Policy)</p>
                              </v-card-text> -->
                            <v-card-text
                              class="pa-0 pt-0 pt-0"
                              style="font-size:14px;margin-right:32px;margin-bottom:-22px"
                              @click.stop=""
                            >
                              {{ $t("landingpage.accept") }}
                              <a @click.prevent="open()">{{
                                $t("landingpage.termsofserviceoneid")
                              }}</a>
                              {{ $t("landingpage.and") }}
                              <a
                                @click.prevent="opendialogpolicyoneid = true"
                                >{{ $t("landingpage.privacypolicy") }}</a
                              >
                            </v-card-text>
                          </template>
                        </v-checkbox>
                      </v-layout>
                    </v-flex>

                    <v-col class="col-md-6 col-sm-6">
                      <v-btn
                        @click="checkAccount"
                        :disabled="!check_button_login || countDownFailedLogin > 0"
                        block
                        style="font-size:16px;font-weight:600; background-color:#184966; color:#FFFFFF; text-shadow: rgba(0, 0, 0, 0.25)"
                        :loading="loading"
                      >                      
                        <!-- เข้าสู่ระบบ -->
                        {{ $t("landingpagenavbar.login") }}
                      </v-btn>
                    </v-col>
                    <v-card-text class="text-center">
                      <span>
                        <!-- สร้างบัญชี ONE-ID ? -->
                        {{ $t("landingpage.createaccount_oneid") }}
                        <a
                          @click="check_easylogin = true"
                          style="text-decoration: none;"
                          target="_blank"
                        >
                          <!-- ลงทะเบียน Easy One ID -->
                          <!-- {{ $t('landingpage.registereasyoneid') }} -->
                          {{ $t("landingpage.registereasyoneid") }}
                        </a>
                      </span>
                    </v-card-text>
                  </div>
                  <!-- login หมอพร้อม -->
                  <div v-if="tab_idcard">
                    <v-card-text class="text-left" style="color:#333333;">
                      <b>{{ $t("idcard") }}</b>
                      <v-text-field
                        :label="$t('idcard')"
                        class="mt-2"
                        outlined
                        solo
                        flat
                        color="#174966"
                        prepend-inner-icon="credit_card"
                        dense
                        :disabled="loading"
                        v-model.trim="idcard"
                        :error-messages="idcardloginError"
                        @input="fn_tolowercase(), $v.idcard.$touch()"
                        @blur="$v.idcard.$touch()"
                      ></v-text-field>
                      <b>{{ $t("password") }}</b>
                      <v-text-field
                        :label="$t('password')"
                        class="mt-2"
                        prepend-inner-icon="lock"
                        outlined
                        solo
                        color="#174966"
                        flat
                        dense
                        :disabled="loading"
                        v-model.trim="password"
                        @click:append="showpassword = !showpassword"
                        :type="showpassword ? 'text' : 'password'"
                        :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :error-messages="passwordloginError"
                        @input="fn_tolowercase(), $v.password.$touch()"
                        @blur="$v.password.$touch()"
                      ></v-text-field>
                    </v-card-text>
                    <v-flex xs11>
                      <v-layout justify-space-between>
                        <v-checkbox
                          v-model="check_memory"
                          color="primary"
                          class="text-center"
                        >
                          <template v-slot:label>
                            <v-card-text
                              class="pa-0 pt-0 "
                              style="font-size:14px"
                              @click.stop=""
                            >
                              {{ $t("landingpage.rememberme") }}
                            </v-card-text>
                          </template>
                        </v-checkbox>

                        <a
                          :href="urloneid + '/type_forgot_password'"
                          style="text-decoration: none; align-self:center; font-size:14px;"
                          target="_blank"
                          ><u>{{ $t("forgotpassword") }}</u></a
                        >
                      </v-layout>
                      <v-layout row wrap justify-center class="pt-0 mb-2">
                        <v-checkbox
                          v-model="check_button_login"
                          color="primary"
                          class="text-center"
                          style="margin-left:13px; margin-top:-10px"
                        >
                          <template v-slot:label>
                            <v-card-text
                              class="pa-0 pt-0"
                              style="font-size:14px;margin-right:32px;margin-bottom:-22px"
                              @click.stop=""
                            >
                              {{ $t("landingpage.accept") }}
                              <a @click.prevent="open()">{{
                                $t("landingpage.termsofserviceoneid")
                              }}</a>
                              {{ $t("landingpage.and") }}
                              <a
                                @click.prevent="opendialogpolicyoneid = true"
                                >{{ $t("landingpage.privacypolicy") }}</a
                              >
                            </v-card-text>
                          </template>
                        </v-checkbox>
                      </v-layout>
                    </v-flex>

                    <v-col class="col-md-6 col-sm-6">
                      <v-btn
                        @click="login"
                        :disabled="!check_button_login"
                        block
                        style="font-size:16px;font-weight:600; background-color:#184966; color:#FFFFFF; text-shadow: rgba(0, 0, 0, 0.25)"
                        :loading="loading"
                      >
                        {{ $t("landingpagenavbar.login") }}
                      </v-btn>
                    </v-col>
                    <v-card-text class="text-center">
                      <span>
                        {{ $t("landingpage.createaccount_oneid") }}
                        <a
                          @click="fn_register_mohpromt"
                          style="text-decoration: none;"
                          target="_blank"
                        >
                          {{ $t("landingpagenavbar.registerone") }}
                        </a>
                      </span>
                    </v-card-text>
                  </div>
                  <!-- login sms -->
                  <div v-if="tab_sms">
                    <div v-if="slideVerify">
                      <v-card-text class="text-left">
                        <b>{{ $t("loginSMS") }}</b>
                        <v-text-field
                          class="mt-2"
                          outlined
                          solo
                          hide-details="auto"
                          flat
                          color="#174966"
                          prepend-inner-icon="phone"
                          dense
                          :disabled="loading"
                          :label="$t('phoneNumber')"
                          v-model.trim="phonenumber"
                          maxlength="10"
                          :error-messages="phonenumberError"
                          @input="fn_tolowercase(), $v.phonenumber.$touch()"
                          @blur="$v.phonenumber.$touch()"
                          :oninput="regexphone"
                        ></v-text-field>
                      </v-card-text>
                      <v-layout row wrap justify-center class="px-6">
                        <v-checkbox
                          v-model="check_button_login"
                          color="primary"
                          class="text-center"
                          style="size:1px"
                        >
                          <template v-slot:label>
                            <v-card-text
                              class="pa-0 pt-0"
                              style="font-size:14px;margin-right:32px;margin-bottom:-22px"
                              @click.stop=""
                            >
                              <!-- ยอมรับ -->
                              {{ $t("landingpage.accept") }}
                              <a @click.prevent="open()">
                                <!-- ข้อกำหนดการใช้บริการ ONE ID -->
                                {{ $t("landingpage.termsofserviceoneid") }}
                              </a>
                              <!-- และ -->
                              {{ $t("landingpage.and") }}
                              <a @click.prevent="opendialogpolicyoneid = true">
                                <!-- นโยบายความคุ้มครองข้อมูลส่วนบุคคล -->
                                {{ $t("landingpage.privacypolicy") }}
                              </a>
                              <!-- <p>(Accept Terms Of Service ONE ID And Privacy Policy)</p> -->
                            </v-card-text>
                          </template>
                        </v-checkbox>
                      </v-layout>
                      <drag-verify
                        v-model="modelValue"
                        style="height: 40px; width: 290px; margin-bottom: 15px;"
                        :disabled="!check_button_login || countDownFailedLogin > 0"
                        :text="$t('otp')"
                        @successFunction="sendOTP()"
                      >
                      </drag-verify>
                      <!-- <drag-verify
                        :disabled="!check_button_login"
                        width="350"
                        height="50"
                        text="เลื่อนไปทางขวาเพื่อรับ OTP"
                        :success-text="successText"
                        background="#005799"
                        color="#ffffff"
                        progress-bar-bg="#FFFF99"
                        completed-bg="#66cc66"
                        handler-bg="#fff"
                        handler-icon=""
                        text-size="20px"
                        success-icon="mdi-check-bold"
                        circle="true"
                      ></drag-verify> -->
                      <!-- <div class="center-xy">
                          <h1>Slide to Unlock</h1>
                          <input type="range" value="0" class="pullee" />
                      </div> -->
                    </div>
                    <div v-else class="px-4">
                      <h3 class="text-center my-4">{{ $t("verifyOTP") }}</h3>
                      <div class="ma-auto position-relative" style="max-width: 100%">
                        <otp-input
                          :disabled="loading"
                          :digits="6"
                          @on-complete="onCompleteOTP"
                          @on-changed="onChangeOTP" 
                          separateInputClass="separate-input-class"
                          placeholder=""
                        ></otp-input>
                        <!-- <v-text-field
                          v-model="otp"
                          class="mt-2"
                          outlined
                          solo
                          hide-details="auto"
                          flat
                          color="#174966"
                          dense
                          :disabled="loading"
                          :label="$t('enterOTP')"
                          maxlength="6"
                        ></v-text-field> -->
                      </div>
                      <v-layout row wrap justify-center class="px-4 py-4">
                        <v-flex lg4 class="d-flex justify-start align-center">
                          <div>{{ $t("countDown") }}{{ formattedCountdownLoginSMS }}</div>
                        </v-flex>
                        <v-flex lg4 class="d-flex justify-end">
                          <v-btn
                            text
                            color="primary"
                            style="text-decoration: underline;"
                            :disabled="!isActiveResendOTP"
                            @click="isActiveResendOTP = false, sendOTP()"
                          >
                            {{ $t("resendOTP") }}
                          </v-btn>
                        </v-flex>
                      </v-layout>
                      <v-btn 
                        block 
                        class="px-8 my-2 white--text" 
                        style="background-color: #184966;" 
                        :disabled="!isActiveConfirmOTP || countDown === 0 || countDownFailedLogin > 0" 
                        @click="login"
                        :loading="loading"
                      >
                        {{ $t('confirmOTP') }}
                      </v-btn>
                    </div>
                  </div>
                  <!-- One Collaboration -->
                    <div class="text-center">
                      <v-dialog
                        v-model="opendialogonecollab"
                        width="400"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn 
                            v-bind="attrs"
                            v-on="on"
                            class="px-3"
                            style="font-size:16px;font-weight:600; background-color:#005799; color:#FFFFFF; text-shadow: rgba(0, 0, 0, 0.25)"
                            >
                            <img
                              :src="require('@/assets/img/inetlogo-mail.png')"
                            >
                            |
                              <!-- เข้าสู่ระบบ One Collaboration Mail -->
                              {{ $t('landingpagenavbar.login_onecollab') }}
                          </v-btn>
                        </template>

                        <v-card>
                          
                          <v-card-title style="background-color:#005799; color:#FFFFFF; justify-content: space-around;">
                            <img :src="require('@/assets/img/inetlogo-mail.png')">
                           <p style="font-size:15px;margin-bottom:0px;"> Login with One Collaboration Mail</p>
                              <v-btn
                              style="margin-bottom:20px;margin-right:-20px;color:#FFFFFF;"
                              fab 
                              icon 
                              x-small
                              @click="(opendialogonecollab = false), (tab_one_collab = false)"
                              >
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                          </v-card-title>

                          <v-card-text
                            class="text-left mt-3" style="color:#33333;">
                            <b>{{ $t("email") }}</b>
                            <v-text-field
                              class="mt-2"
                              outlined
                              solo
                              hide-details
                              flat
                              color="#174966"
                              prepend-inner-icon="mdi-email"
                              dense
                              :disabled="loading"
                              v-model.trim="email_one_collab"
                              @input="fn_tolowercase()"
                            ></v-text-field>
                            <br />
                            <b>{{ $t("password") }}</b>
                            <v-text-field
                              class="mt-2"
                              prepend-inner-icon="lock"
                              outlined
                              solo
                              color="#174966"
                              flat
                              dense
                              hide-details
                              :disabled="loading"
                              v-model.trim="password_one_collab"
                              @click:append="showpassword_one_collab = !showpassword_one_collab"
                              :type="showpassword_one_collab ? 'text' : 'password'"
                              :append-icon="showpassword_one_collab ? 'mdi-eye' : 'mdi-eye-off'"
                            ></v-text-field>
                          </v-card-text>
                        
                          <v-col style="text-align-last: center;">
                            <v-btn 
                              class="px-5 mb-3"
                              @click="tab_one_collab = true, login()"                       
                              style="font-size:16px;font-weight:600; background-color:#005799; color:#FFFFFF; text-shadow: rgba(0, 0, 0, 0.25)"
                              :loading="loading"
                              >
                                <!-- เข้าสู่ระบบ -->
                                {{ $t('landingpagenavbar.login') }}
                            </v-btn>
                          </v-col>

                          <v-divider></v-divider>
                        </v-card>
                      </v-dialog>
                    </div>
                    <!-- login with azure id -->
                    <div class="pa-3">
                      <v-btn
                        @click="loginAzureID"                        
                        block
                        style="font-size:16px; font-weight:600; background-color:#005799; color:#FFFFFF; text-shadow: rgba(0, 0, 0, 0.25)"
                        :loading="loading_azure"
                        >
                          {{ $t('landingpagenavbar.login_azure_id') }}
                      </v-btn>
                    </div>
                </v-col>

                <v-col
                  v-else
                  md="6"
                  class="fill-height pa-4"
                  align="center"
                  justify="center"
                >
                  <v-flex xs11>
                    <v-layout justify-center>
                      <v-icon class="mr-3 mb-4" style="color:#184966"
                        >mdi-account-plus</v-icon
                      >
                      <h2 class="mb-5" style="font-size:18px;color:#184966">
                        <!-- REGISTER -->
                        {{ $t("landingpage.registeruserheader") }}
                      </h2>
                    </v-layout>
                    <v-stepper
                      dark
                      style="background: linear-gradient(to right,#184966, #5AB685);"
                      v-model="e1"
                      class="mb-5 ml-1 mr-1"
                    >
                      <v-stepper-header>
                        <v-stepper-step :complete="e1 > 1" step="1">
                          <!-- ลงทะเบียนผู้ใช้งาน -->
                          {{ $t("landingpage.registeruserheader") }}
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="2">
                          <!-- เสร็จสิ้น -->
                          {{ $t("landingpage.success") }}
                        </v-stepper-step>
                      </v-stepper-header>
                    </v-stepper>
                    <div v-if="e1 == 1">
                      <!-- label="เบอร์โทรศัพท์ / Mobile No." -->
                      <v-text-field
                        clearable
                        solo
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0"
                        v-model="phonenumber"
                        :label="$t('landingpage.mobileno')"
                        maxlength="10"
                        prepend-inner-icon="phone"
                        :rules="requiredPhoneNumber"
                        required
                        :error-messages="phonenumberError"
                        @input="$v.phonenumber.$touch()"
                        @blur="$v.phonenumber.$touch()"
                      ></v-text-field>
                      <!-- label="ชื่อผู้ใช้ / Username" -->
                      <v-text-field
                        clearable
                        solo
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0"
                        v-model="username_register"
                        :label="$t('landingpage.username')"
                        prepend-inner-icon="people"
                        :rules="requiredusername"
                        required
                        :error-messages="usernameError"
                        @input="fn_tolowercase(), $v.username_register.$touch()"
                        @blur="$v.username_register.$touch()"
                      ></v-text-field>
                      <!-- label="รหัสผ่าน / Password" -->
                      <v-text-field
                        clearable
                        solo
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0"
                        v-model="password_register"
                        :label="$t('landingpage.password')"
                        prepend-inner-icon="vpn_key"
                        @click:append="
                          showpassword_register = !showpassword_register
                        "
                        :type="showpassword_register ? 'text' : 'password'"
                        :append-icon="
                          showpassword_register ? 'mdi-eye' : 'mdi-eye-off'
                        "
                        :rules="requiredpassword"
                        :error-messages="passwordError"
                        required
                        @input="$v.password_register.$touch()"
                        @blur="$v.password_register.$touch()"
                      ></v-text-field>
                      <!-- label="ยืนยันรหัสผ่าน / ConfirmPassword" -->
                      <v-text-field
                        clearable
                        solo
                        outlined
                        dense
                        class="pa-1 pt-0 pb-0"
                        v-model="confirmpassword_register"
                        :label="$t('landingpage.confirmpassword')"
                        prepend-inner-icon="vpn_key"
                        @click:append="
                          showconfirmpassword_register = !showconfirmpassword_register
                        "
                        :type="
                          showconfirmpassword_register ? 'text' : 'password'
                        "
                        :append-icon="
                          showconfirmpassword_register
                            ? 'mdi-eye'
                            : 'mdi-eye-off'
                        "
                        :rules="requiredconfirmpassword"
                        :error-messages="confirmpasswordError"
                        required
                        @input="$v.confirmpassword_register.$touch()"
                        @blur="$v.confirmpassword_register.$touch()"
                      ></v-text-field>
                      <v-flex xs11>
                        <v-layout row wrap justify-center mb-2>
                          <v-checkbox
                            v-model="check_accept"
                            color="primary"
                            class="text-center ml-1"
                            style="margin-left:13px; margin-top:-5px"
                          >
                            <template v-slot:label>
                              <v-card-text
                                class="pa-0 pt-0"
                                style="font-size:14px;margin-left:-10px;margin-bottom:-22px"
                                @click.stop=""
                              >
                                <!-- ยอมรับ -->
                                {{ $t("landingpage.accept") }}
                                <a @click.prevent="open()">
                                  <!-- ข้อกำหนดการใช้บริการ ONE ID -->
                                  {{ $t("landingpage.termsofserviceoneid") }}
                                </a>
                                <!-- และ -->
                                {{ $t("landingpage.and") }}
                                <a
                                  @click.prevent="opendialogpolicyoneid = true"
                                >
                                  <!-- นโยบายความคุ้มครองข้อมูลส่วนบุคคล -->
                                  {{ $t("landingpage.privacypolicy") }}
                                </a>
                                <!-- <p>(Accept Terms Of Service ONE ID And Privacy Policy)</p> -->
                              </v-card-text>
                            </template>
                          </v-checkbox>
                        </v-layout>
                      </v-flex>
                      <v-col class="col-md-6 col-sm-6">
                        <v-btn
                          :loading="createprogress"
                          :disabled="!check_accept"
                          @click="fn_register()"
                          block
                          style="font-size:16px;font-weight:600; background-color:#184966; color:#FFFFFF; text-shadow: rgba(0, 0, 0, 0.25)"
                        >
                          <!-- ลงทะเบียน -->
                          {{ $t("landingpage.registerbtn") }}
                        </v-btn>
                      </v-col>
                      <v-card-text class="text-center">
                        <span>
                          <!-- คุณมีบัญชี One Platform ใช่หรือไม่ ? -->
                          {{ $t("landingpage.haveanaccount") }}
                          <a
                            @click="check_easylogin = false"
                            style="text-decoration: none;"
                            target="_blank"
                          >
                            <!-- ลงชื่อเข้าใช้งาน -->
                            {{ $t("landingpage.loginlink") }}
                          </a>
                        </span>
                      </v-card-text>
                    </div>
                    <div v-else>
                      <v-card style="background: #E3F2FD" width="500">
                        <v-card-text>
                          <v-layout row wrap justify-center>
                            <v-flex xs12 sm12 md12 lg12 class="text-center">
                              <v-icon 
                              size="60px" 
                              color="#5AB685"
                                >mdi-check-circle-outline</v-icon
                              >
                              <br /><br />
                              <!-- <h2 style="font-size:18px">ลงทะเบียนสำเร็จ</h2><br>
                            <h3 style="color:#00C853;">( Register Easy OneID  Successfully )</h3> -->
                              <h2 style="font-size:20px;margin-top:-10px">
                                {{ $t("landingpage.registersuccessfully") }}
                              </h2>
                            </v-flex>
                          </v-layout>
                          <v-layout row wrap justify-center>
                            <v-flex xs12 sm6 md8 lg6>
                              <v-card
                                class="mx-auto"
                                max-width="300"
                                max-height="200"
                                elevation="0"
                                style="background: #E3F2FD;"
                              >
                                <!-- <v-card-text>       
                        <p style="text-align: center; color:red;">โปรดจำข้อมูลนี้ !!</p>
                        <p style="padding-left: 30px; text-align: left;"><strong>username :</strong> {{ username_register }}</p>
                        <p style="padding-left: 30px; text-align: left;"><strong>อีเมล :</strong> {{ email }}</p>
                        <p style="padding-left: 30px; text-align: left;"><strong>เบอร์โทร :</strong> {{ phonenumber }}</p>
                      </v-card-text> -->
                      
                                <v-card-text>
                                  <p
                                    style="text-align: center; color:red;font-size:16px"
                                  >
                                    ( {{ $t("landingpage.pleaseremember") }} )
                                  </p>
                                  <div
                                    style="padding-left: 0px; text-align: center;font-size:16px"
                                  >
                                  <!-- <v-icon size="25" class="mr-2 mb-2">mdi-account</v-icon> -->
                                      <!-- {{
                                        $t("landingpage.username")
                                      }}
                                      : -->
                                      <v-icon size="25" class="mr-1 mb-2">mdi-account</v-icon>
                                      <!-- {{$t("landingpage.username")}} -->
                                      ชื่อผู้ใช้งาน / Username
                                  
                                  <br>
                                    <!-- <v-icon style="margin-bottom:3px">mdi-menu-right-outline</v-icon> -->
                                    <b>{{ username_register }}</b>
                                    <!-- <v-icon style="margin-bottom:3px">mdi-menu-left-outline</v-icon> -->
                                  </div><br>
                                
                                  <!-- <p style="padding-left: 30px; text-align: left;"><strong>อีเมล :</strong> {{ email }}</p> -->
                                <div
                                    style="text-align: center;font-size:16px"
                                  >
                                    <!-- <strong
                                      >{{
                                        $t("landingpage.mobileno")
                                      }}
                                      :</strong
                                    > -->
                                    <v-icon class="mr-2 mb-2">mdi-phone-classic</v-icon>
                                    <!-- {{$t("landingpage.mobileno")}} -->
                                    เบอร์โทรศัพท์ / Phone Number
                                    <br>
                                    <!-- <v-icon style="margin-bottom:3px">mdi-menu-right-outline</v-icon> -->
                                    <b>{{ phonenumber }}</b>
                                    <!-- <v-icon style="margin-bottom:3px">mdi-menu-left-outline</v-icon> -->
                                  </div>
                                </v-card-text>
                              </v-card>
                            </v-flex>
                          </v-layout>
                          <v-layout row wrap justify-center class="mt-0">
                            <v-flex class="text-center" xs6>
                              <v-btn
                                class="mt-2 mb-2"
                                style="font-size:16px;font-weight:600; background-color:#184966; color:#FFFFFF; text-shadow: rgba(0, 0, 0, 0.25)"
                                block
                                @click="(check_easylogin = false),(e1 = 1),fn_cleardata()"
                              >
                                <!-- เข้าสู่ระบบ
                                <br>(Log in) -->
                                {{ $t("landingpagenavbar.login") }}
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-flex>
                </v-col>
              </v-card>
            </v-row>
          </v-container>
          <v-container v-else>
            <v-row no-gutters>
              <v-col class="text-center" cols="12" md="6">
                <v-row class="fill-height white--text" justify="center">
                  <v-col cols="12">
                    <v-layout justify-center>
                      <img
                        style="margin-top:-30px"
                        src="@/assets/logo_notebook.png"
                        width="300px"
                      />
                    </v-layout>
                    <v-layout justify-center>
                      <img
                        style="margin-top:-20px;margin-right:85px"
                        :src="
                          require('@/assets/img/logo_onebox-horizontal-white.png')
                        "
                        width="170px"
                      />
                    </v-layout>
                    <v-layout justify-center>
                      <p
                        class="ml-0 mb-0 white--text"
                        style="font-size:18px;margin-right:-100px;margin-top:-25px"
                      >
                        Human Trusted IT Service
                      </p>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                md="6"
                class="pa-4 pt-0 mt-10"
                align="center"
                justify="center"
              >
                <h2
                  class="mt-0 mb-0 white--text"
                  style="font-size:36px;text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;"
                >
                  <!-- Log in -->
                  {{ $t("landingpagenavbar.login") }}
                </h2>
                <br />

                <div>
                  <v-card-text class="text-center" style="color:white;">
                    <h2>{{ $t("username") }} : {{ username_show }}</h2>
                    <!-- <v-text-field 
                    class="mt-2"
                    outlined
                    solo
                    hide-details
                    flat
                    color="#174966"
                    prepend-inner-icon="account_box"
                    dense
                    disabled
                    v-model="username_show"
                  ></v-text-field> -->
                    <br />
                  </v-card-text>
                  <!-- background-color:#005799; -->
                  <v-flex xs6>
                    <v-col class="col-md-12 col-sm-12">
                      <v-btn
                        @click="checkAccount"
                        block
                        style="font-size:16px;font-weight:600; background-color:#FFFFFF; color:#005799; text-shadow: rgba(0, 0, 0, 0.25)"
                        :loading="loading"
                      >
                        <!-- เข้าสู่ One Box -->
                        {{ $t("landingpagenavbar.gotoonebox") }}
                      </v-btn>
                      <span><br /><br /></span>
                      <v-btn
                        @click="checkLogoutAccount"
                        block
                        style="font-size:16px;font-weight:600; background-color:#FFFFFF; color:#005799; text-shadow: rgba(0, 0, 0, 0.25)"
                      >
                        <!-- ออกจากระบบ -->
                        {{ $t("landingpagenavbar.logout") }}
                      </v-btn>
                    </v-col>
                  </v-flex>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <dialogoneid
            :show="opendialogoneid"
            @closedialog="opendialogoneid = false"
          ></dialogoneid>
          <dialogpolicyoneid
            :show="opendialogpolicyoneid"
            @closedialog="opendialogpolicyoneid = false"
          ></dialogpolicyoneid>
        </section>
      </div>

      <!-- background: linear-gradient(to bottom, #8dbff2, #1E88E5, #1976D2);" -->
      <!-- <section class="white" style="color:#1A237E">
        <v-container>
              <v-row class="fill-height white--text" align="center" justify="center">
                <v-col cols="4">
                  <v-container grid-list-xs>
                    <v-card class="mx-auto" max-width="344">
                      <v-card-text>
                        <p class="text-h4 text--primary">
                         300 บาท/เดือน
                        </p>
                        <p>adjective</p>
                        <div class="text--primary">
                          relating to or dependent on charity; charitable.<br />
                          "an eleemosynary educational institution."
                        </div>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn text color="teal accent-4" @click="reveal = true">
                          Learn More
                        </v-btn>
                      </v-card-actions>

                      <v-expand-transition>
                        <v-card v-if="reveal" class="transition-fast-in-fast-out v-card--reveal" style="height: 100%;">
                          <v-card-text class="pb-0">
                            <p class="text-h4 text--primary">
                              Origin
                            </p>
                            <p>
                              late 16th century (as a noun denoting a place where alms were distributed): from medieval Latin
                              eleemosynarius, from late Latin eleemosyna ‘alms’, from Greek eleēmosunē ‘compassion’
                            </p>
                          </v-card-text>
                          <v-card-actions class="pt-0">
                            <v-btn text color="teal accent-4" @click="reveal = false">
                              Close
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-expand-transition>
                    </v-card>
                  </v-container>
                </v-col>
                <v-col cols="4">
                  <v-container grid-list-xs>
                    <v-card class="mx-auto" max-width="344">
                      <v-card-text>
                        <p class="text-h4 text--primary">
                         575 บาท/เดือน
                        </p>
                        <p>adjective</p>
                        <div class="text--primary">
                          relating to or dependent on charity; charitable.<br />
                          "an eleemosynary educational institution."
                        </div>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn text color="teal accent-4" @click="reveal = true">
                          Learn More
                        </v-btn>
                      </v-card-actions>

                      <v-expand-transition>
                        <v-card v-if="reveal" class="transition-fast-in-fast-out v-card--reveal" style="height: 100%;">
                          <v-card-text class="pb-0">
                            <p class="text-h4 text--primary">
                              Origin
                            </p>
                            <p>
                              late 16th century (as a noun denoting a place where alms were distributed): from medieval Latin
                              eleemosynarius, from late Latin eleemosyna ‘alms’, from Greek eleēmosunē ‘compassion’
                            </p>
                          </v-card-text>
                          <v-card-actions class="pt-0">
                            <v-btn text color="teal accent-4" @click="reveal = false">
                              Close
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-expand-transition>
                    </v-card>
                  </v-container>
                </v-col>
                 <v-col cols="4">
                  <v-container grid-list-xs>
                    <v-card class="mx-auto" max-width="344">
                      <v-card-text>
                        <p class="text-h4 text--primary">
                          el·ee·mos·y·nar·y
                        </p>
                        <p>adjective</p>
                        <div class="text--primary">
                          relating to or dependent on charity; charitable.<br />
                          "an eleemosynary educational institution."
                        </div>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn text color="teal accent-4" @click="reveal = true">
                          Learn More
                        </v-btn>
                      </v-card-actions>

                      <v-expand-transition>
                        <v-card v-if="reveal" class="transition-fast-in-fast-out v-card--reveal" style="height: 100%;">
                          <v-card-text class="pb-0">
                            <p class="text-h4 text--primary">
                              Origin
                            </p>
                            <p>
                              late 16th century (as a noun denoting a place where alms were distributed): from medieval Latin
                              eleemosynarius, from late Latin eleemosyna ‘alms’, from Greek eleēmosunē ‘compassion’
                            </p>
                          </v-card-text>
                          <v-card-actions class="pt-0">
                            <v-btn text color="teal accent-4" @click="reveal = false">
                              Close
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-expand-transition>
                    </v-card>
                  </v-container>
                </v-col>
                 <v-col cols="4">
                  <v-container grid-list-xs>
                    <v-card class="mx-auto" max-width="344">
                      <v-card-text>
                        <div>Word of the Day</div>
                        <p class="text-h4 text--primary">
                          el·ee·mos·y·nar·y
                        </p>
                        <p>adjective</p>
                        <div class="text--primary">
                          relating to or dependent on charity; charitable.<br />
                          "an eleemosynary educational institution."
                        </div>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn text color="teal accent-4" @click="reveal = true">
                          Learn More
                        </v-btn>
                      </v-card-actions>

                      <v-expand-transition>
                        <v-card v-if="reveal" class="transition-fast-in-fast-out v-card--reveal" style="height: 100%;">
                          <v-card-text class="pb-0">
                            <p class="text-h4 text--primary">
                              Origin
                            </p>
                            <p>
                              late 16th century (as a noun denoting a place where alms were distributed): from medieval Latin
                              eleemosynarius, from late Latin eleemosyna ‘alms’, from Greek eleēmosunē ‘compassion’
                            </p>
                          </v-card-text>
                          <v-card-actions class="pt-0">
                            <v-btn text color="teal accent-4" @click="reveal = false">
                              Close
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-expand-transition>
                    </v-card>
                  </v-container>
                </v-col>
                 <v-col cols="4">
                  <v-container grid-list-xs>
                    <v-card class="mx-auto" max-width="344">
                      <v-card-text>
                        <div>Word of the Day</div>
                        <p class="text-h4 text--primary">
                          el·ee·mos·y·nar·y
                        </p>
                        <p>adjective</p>
                        <div class="text--primary">
                          relating to or dependent on charity; charitable.<br />
                          "an eleemosynary educational institution."
                        </div>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn text color="teal accent-4" @click="reveal = true">
                          Learn More
                        </v-btn>
                      </v-card-actions>

                      <v-expand-transition>
                        <v-card v-if="reveal" class="transition-fast-in-fast-out v-card--reveal" style="height: 100%;">
                          <v-card-text class="pb-0">
                            <p class="text-h4 text--primary">
                              Origin
                            </p>
                            <p>
                              late 16th century (as a noun denoting a place where alms were distributed): from medieval Latin
                              eleemosynarius, from late Latin eleemosyna ‘alms’, from Greek eleēmosunē ‘compassion’
                            </p>
                          </v-card-text>
                          <v-card-actions class="pt-0">
                            <v-btn text color="teal accent-4" @click="reveal = false">
                              Close
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-expand-transition>
                    </v-card>
                  </v-container>
                </v-col>
              </v-row>
        </v-container>
      </section> -->
      <!-- background: linear-gradient(to bottom, #1976D2, #1E88E5, #E3F2FD);" -->
      <section
        style="background: #E3F2FD;
        background: linear-gradient(to top, #1976D2, #8dbff2);"
        fixed-tabs
      >
        <v-container>
          <v-row no-gutters>
            <v-col class="text-center pa-5" cols="12" md="6">
              <v-row
                class="fill-height white--text"
                align="center"
                justify="center"
              >
                <v-col cols="12">
                  <v-container grid-list-xs>
                    <img
                      class="grow hidden-sm-and-down"
                      contain
                      max-height="100"
                      width="35%"
                      position="top left"
                      :src="require('@/assets/img/bubbles1.png')"
                      style="transform: translateX(55%)"
                    />
                    <!-- <h2 style="text-transform: uppercase;">
                      สมัครง่าย สะดวก รวดเร็ว ด้วย ONEBOX
                    </h2>
                    <br />
                    <h4 class="pb-2">
                      รองรับการใช้งานผ่าน เบราว์เซอร์
                    </h4> -->
                    <h2 style="text-transform: uppercase;">
                      {{ $t("landingpage.contentsection1.title") }}
                    </h2>
                    <br />
                    <h4 class="pb-2">
                      {{ $t("landingpage.contentsection1.item") }}
                    </h4>
                    <v-layout class="mb-2" justify-center>
                      <img
                        class="mr-3"
                        width="30px"
                        src="@/assets/logo_google.png"
                      />
                      <img
                        class="mr-3"
                        width="30px"
                        src="@/assets/logo_Edge.png"
                      />
                      <img
                        class="mr-3"
                        width="30px"
                        src="@/assets/logo_Safari.png"
                      />
                    </v-layout>
                    <v-divider></v-divider>
                    <br />
                    <!-- :href="urloneid + '/register'" -->
                    <!-- style="border-radius:15px 5px;"  -->
                    <v-layout row justify-center v-if="mohpormt == true">
                      <v-flex lg12>
                        <v-btn
                          color="white"
                          rounded
                          @click="$router.push('/register_mohpromt2')"
                          target="_blank"
                          :disabled="maintenanceStatus"
                        >
                          <!-- ลงทะเบียนผู้ใช้ one platform -->
                          {{ $t("landingpagenavbar.registerone") }}
                        </v-btn>
                      </v-flex>
                      <span><br /><br /></span>
                      <v-flex lg12>
                        <v-btn
                          color="white"
                          rounded
                          @click="$router.push('/register')"
                          target="_blank"
                          :disabled="maintenanceStatus"
                        >
                          <!-- ลงทะเบียนบัญชีนิติบุคคล -->
                          {{ $t("landingpagenavbar.registerbusiness") }}
                        </v-btn>
                      </v-flex>
                    </v-layout>
                    <v-layout row justify-center v-else>
                      <v-flex lg12>
                        <v-btn
                          color="white"
                          rounded
                          @click="$router.push('/registerone')"
                          target="_blank"
                          :disabled="maintenanceStatus"
                        >
                          <!-- ลงทะเบียนผู้ใช้ one platform -->
                          {{ $t("landingpagenavbar.registerone") }}
                        </v-btn>
                      </v-flex>
                      <span><br /><br /></span>
                      <v-flex lg12>
                        <v-btn
                          color="white"
                          rounded
                          @click="$router.push('/register')"
                          target="_blank"
                          :disabled="maintenanceStatus"
                        >
                          <!-- ลงทะเบียนบัญชีนิติบุคคล -->
                          {{ $t("landingpagenavbar.registerbusiness") }}
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <center class="justify-center align-center">
                <br />
                <video
                  v-if="resolutionScreen >= 400"
                  class="pb-10 pt-0"
                  width="570"
                  height="490"
                  controls
                  muted
                  id="vid"
                >
                  <source src="@/assets/PR onebox.mp4" type="video/mp4" />
                </video>
                <video
                  v-else
                  class="pb-10 pt-0"
                  width="300"
                  height="220"
                  controls
                  muted
                  id="vid"
                >
                  <source src="@/assets/PR onebox.mp4" type="video/mp4" />
                </video>
                <!-- <v-img
                  :src="require('@/assets/img/logo-3.png')"
                  width="55%"
                /> -->
                <br />
              </center>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <section class="white " style="color:#1A237E">
        <v-container>
          <v-container grid-list-xs>
            <h2 class="text-center mt-10 mb-10">
              <!-- ความสะดวกในการใช้งานและความสามารถในการแชร์ไฟล์ -->
              {{ $t("landingpage.contentsection2.title") }}
            </h2>
          </v-container>
          <v-row no-gutters>
            <v-col class="text-center pa-5" cols="12" md="6">
              <v-row class="fill-height" align="center" justify="center">
                <v-col cols="12">
                  <v-container grid-list-xs>
                    <h2>
                      <!-- สะดวกทุกที่ทุกเวลา -->
                      {{ $t("landingpage.contentsection2.subtitle1") }}
                    </h2>
                    <br />
                    <v-divider></v-divider>
                    <br />
                    <v-card-text class="mb-5" color="white">
                      <!-- สามารถเข้าถึงพื้นที่จัดเก็บไฟล์ได้ทุกที่ ทุกเวลา และทุกอุปกรณ์ผ่าน Web Application -->
                      {{ $t("landingpage.contentsection2.item1") }}
                    </v-card-text>
                  </v-container>
                </v-col>
              </v-row>
            </v-col>

            <v-col md="6" class="fill-height" align="center" justify="center">
              <v-img :src="require('@/assets/img/010.png')" width="60%" />
            </v-col>
          </v-row>
          <br />
          <v-row no-gutters>
            <v-col class="text-center pa-5" cols="12" md="6">
              <v-row class="fill-height" align="center" justify="center">
                <v-col cols="12">
                  <v-container grid-list-xs>
                    <h2>
                      <!-- แชร์ไฟล์ได้หลายช่องทาง -->
                      {{ $t("landingpage.contentsection2.subtitle2") }}
                    </h2>
                    <br />
                    <v-divider></v-divider>
                    <br />
                    <v-card-text class="mb-5" color="white">
                      <!-- สามารถแชร์ไฟล์ให้ผู้อื่นเข้ามาดาวน์โหลดได้โดยไม่ต้องส่ง link และยังสามารถส่งไฟล์ผ่าน chat และ e-mail ได้ -->
                      {{ $t("landingpage.contentsection2.item2") }}
                    </v-card-text>
                  </v-container>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="6" class="fill-height" align="center" justify="center">
              <v-img :src="require('@/assets/img/012.png')" width="60%" />
            </v-col>
          </v-row>
        </v-container>
      </section>
      <section class="blue lighten-5 hidden-sm-and-down" style="color:#1A237E">
        <v-parallax :src="require('@/assets/img/landing.png')">
          <v-container grid-list-xs>
            <h1 class="text-center pa-10">
              <!-- หมดความกังวลเรื่องการเก็บไฟล์และความปลอดภัยของข้อมูล -->
              {{ $t("landingpage.contentsection3.title") }}
            </h1>
            <v-layout row wrap justify-center align-center>
              <v-flex xs11 lg10>
                <v-layout row wrap justify-center align-center>
                  <v-flex xs12 lg4 pt-6>
                    <v-card-actions>
                      <v-icon dark large>mdi-shield-lock-outline </v-icon>
                    </v-card-actions>
                    <v-card-title primary-title>
                      <!-- ความปลอดภัยของข้อมูล -->
                      {{ $t("landingpage.contentsection3.subtitle1") }}
                    </v-card-title>
                    <v-card-text>
                      <!-- สามารถจัดเก็บไฟล์ได้ทุกประเภทข้อมูลถูกเก็บรักษาอย่างปลอดภัยบนฐานข้อมูล ในประเทศไทยโปร่งใสตรวจสอบได้ -->
                      {{ $t("landingpage.contentsection3.item1") }}
                    </v-card-text>
                  </v-flex>
                  <v-flex xs12 lg4>
                    <v-card-actions>
                      <v-icon dark large>mdi-shield-check-outline </v-icon>
                    </v-card-actions>
                    <v-card-title primary-title>
                      <!-- ป้องกันข้อมูลภายในของบริษัท -->
                      {{ $t("landingpage.contentsection3.subtitle2") }}
                    </v-card-title>
                    <v-card-text>
                      <!-- ป้องกันข้อมูลภายในของบริษัทด้วยการจำกัดสิทธิ์การเข้าถึงไฟล์ -->
                      {{ $t("landingpage.contentsection3.item2") }}
                    </v-card-text>
                  </v-flex>
                  <v-flex xs12 lg4>
                    <v-card-actions>
                      <v-icon dark large>mdi-shield-star-outline </v-icon>
                    </v-card-actions>
                    <v-card-title primary-title>
                      <!-- Advance Search -->
                      {{ $t("landingpage.contentsection3.subtitle3") }}
                    </v-card-title>
                    <v-card-text>
                      <!-- สามารถค้นหาไฟล์จากเนื้อหาสำคัญในเอกสารได้อย่างง่ายดาย -->
                      {{ $t("landingpage.contentsection3.item3") }}
                    </v-card-text>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-parallax>
      </section>
      <section class="indigo hidden-sm-and-up white--text pb-5">
        <v-container grid-list-xs>
          <h1 class="text-center pa-10">
            <!-- หมดความกังวลเรื่องการเก็บไฟล์และความปลอดภัยของข้อมูล -->
            {{ $t("landingpage.contentsection3.title") }}
          </h1>
          <v-layout row wrap justify-center align-center>
            <v-flex xs11 lg10>
              <v-layout row wrap justify-center align-center>
                <v-flex xs12 lg4 pt-6>
                  <v-card-actions>
                    <v-icon dark large>mdi-shield-lock-outline </v-icon>
                  </v-card-actions>
                  <v-card-title primary-title>
                    <!-- ความปลอดภัยของข้อมูล -->
                    {{ $t("landingpage.contentsection3.subtitle1") }}
                  </v-card-title>
                  <v-card-text>
                    <!-- สามารถจัดเก็บไฟล์ได้ทุกประเภทข้อมูลถูกเก็บรักษาอย่างปลอดภัยบนฐานข้อมูล ในประเทศไทยโปร่งใสตรวจสอบได้ -->
                    {{ $t("landingpage.contentsection3.item1") }}
                  </v-card-text>
                </v-flex>
                <v-flex xs12 lg4>
                  <v-card-actions
                    ><v-icon dark large>mdi-shield-check-outline </v-icon>
                  </v-card-actions>
                  <v-card-title primary-title>
                    <!-- ป้องกันข้อมูลภายในของบริษัท -->
                    {{ $t("landingpage.contentsection3.subtitle2") }}
                  </v-card-title>
                  <v-card-text>
                    <!-- ป้องกันข้อมูลภายในของบริษัทด้วยการจำกัดสิทธิ์การเข้าถึงไฟล์ -->
                    {{ $t("landingpage.contentsection3.item2") }}
                  </v-card-text>
                </v-flex>
                <v-flex xs12 lg4>
                  <v-card-actions>
                    <v-icon dark large>mdi-shield-star-outline </v-icon>
                  </v-card-actions>
                  <v-card-title primary-title>
                    <!-- Advance Search -->
                    {{ $t("landingpage.contentsection3.subtitle3") }}
                  </v-card-title>
                  <v-card-text>
                    <!-- สามารถค้นหาไฟล์จากเนื้อหาสำคัญในเอกสารได้อย่างง่ายดาย -->
                    {{ $t("landingpage.contentsection3.item3") }}
                  </v-card-text>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
      </section>
    </v-content>
    <Footer />
    <template>
      <div class="text-end mr-5 bounce" id="alertnews">
        <!-- v-if="checkbuttonalert == false" -->
        <v-btn
          class="text-right"
          color="#D73B2F"
          fab
          dark
          @click="close_dialog()"
          ><v-icon color="#f5f5f5">mdi-bell-ring</v-icon>
        </v-btn>
      </div>
    </template>
    <Newastorsge
      :show="opendialognewstorage"
      @closedialog="(opendialognewstorage = false), (checkbuttonalert = false)"
    ></Newastorsge>

    <div v-if="dialogOtp">
    <dialogbotboxotp
      :showVerify="showdialogOtp"
      :infor_data="infor_granted"
      @cancelandclosedialogotp="closeDialogOtpVerify"
      @verifyotp="login_with_botonebox_firsttime"
    >
    </dialogbotboxotp>
    </div>

    <SessionExpired :show="dialogSessionExpired" @close="dialogSessionExpired = false"></SessionExpired>
    <dialogmaintenance
      :show="opendialogmaintenance && maintenanceStatus"
      @close="opendialogmaintenance = false"
    ></dialogmaintenance>
    <dialogalertpasswordexpired
    :show="opendialogalertpasswordexpired"
    @close="opendialogalertpasswordexpired = false"
    :data="data_password_expired"
    >
    </dialogalertpasswordexpired>
    <dialog2faotp
    :show="opendialog2faotp"
    :data2fa="data2fa"
    :loginloading="loading"
    @cancelandclosedialogotp="delay_remove2fa"
    @close="opendialog2faotp = false"
    @relogin="login2fa"
    ></dialog2faotp>
    <dialog2faerrornophonenumber
    :show="opendialog2faerror103"
    :data2fa="data2fa"
    @close="opendialog2faerror103 = false"
    ></dialog2faerrornophonenumber>
    <dialogchecklogindeducatenobiz
    :show="opendialogchecklogindeducatenobiz"
    @closedialog="opendialogchecklogindeducatenobiz = false"
    ></dialogchecklogindeducatenobiz>
  </v-app>
</template>

<script>
import Navbar from "./layout-landingpage-navbar";
import Footer from "./layout-landingpage-footer";
import Newastorsge from "../optional/dialog-newsstorage";
import VueCookies from "vue-cookies";
import SessionExpired from "../optional/dialog-sessionexpired";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapGetters, mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import axios from "axios";
import CryptoJS from "crypto-js";
// import dragVerify from "vue-drag-verify";
import dragVerify from "../optional/drag-verify";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import OtpInput from "otp-input-vue2";
import { log } from 'console';
const dialogoneid = () => import("../optional/dialog-dialogoneid.vue");
const dialogbotboxotp = () => import("../optional/dialog-boxbototp.vue")
const dialogpolicyoneid = () =>
  import("../optional/dialog-dialogpolicyoneid.vue");
const dialogmaintenance = () => import("../optional/dialog-maintenance.vue");
const dialogalertpasswordexpired = () => import("../optional/dialog-alert-passwordexpired.vue");
const dialog2faotp = () => import("../optional/dialog-2faotp.vue");
const dialog2faerrornophonenumber = () => import("../optional/dialog-2faerror103.vue");
const dialogchecklogindeducatenobiz = () => import("../optional/dialog-checklogindedicate-nobiz.vue");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  mixins: [validationMixin],
  validations: {
    phonenumber: { required },
    username_register: { required },
    password_register: { required },
    confirmpassword_register: { required },
    username: { required },
    password: { required },
    idcard: { required },
  },
  components: {
    Navbar,
    Footer,
    Newastorsge,
    SessionExpired,
    dialogoneid,
    dialogpolicyoneid,
    dialogmaintenance,
    dialogbotboxotp,
    dragVerify,
    OtpInput,
    dialogalertpasswordexpired,
    dialog2faotp,
    dialog2faerrornophonenumber,
    dialogchecklogindeducatenobiz
  },
  data: function() {
    return {
      opendialogchecklogindeducatenobiz:false,
      businessIndomain:[],
      dataloginbusiness:[],
      opendialog2faotp:false,
      opendialog2faerror103:false,
      // isFailLogin: false,
      // failedLogin: localStorage.getItem("failedLogin") || 0,
      data_password_expired:[],
      data2fa:[],
      opendialogalertpasswordexpired:false,
      showdialogOtp: false,
      dialogOtp: false,
      infor_granted: null,
      regexphone: "this.value = this.value.replace(/[^0-9]|/g, '').substr(0,13)",
      countDownFailedLogin: 0,
      system_type_run:"",
      opendialogonecollab: false,
      active: null,
      isActiveResendOTP: false,
      modelValue: "0",
      mohpormt: false,
      step1: 0,
      idcard: "",
      slideVerify: true,
      countDown : 0,
      user_id: sessionStorage.getItem("user_id"),
      checklogin_cookie: (VueCookies.get("token") == null && sessionStorage.getItem("token") === null) || VueCookies.get("username") === null ? "false" : "true",
      username_show: "",
      checkbox_remember: "",
      check_memory: false,
      maintenanceStatus: false,
      opendialogmaintenance: true,
      opendialogoneid: false,
      opendialogpolicyoneid: false,
      tab_username: true,
      tab_idcard: false,
      tab_sms: false,
      tab_one_collab:false,
      e1: 1,
      valid: "",
      allowcheckpassword: "",
      allowcheckuser: "",
      check_accept: false,
      checkregistersuccess: false,
      createprogress: false,
      checkbox: false,
      loading: false,
      loading_azure: false,
      otp: '',
      isActiveConfirmOTP: false,
      username: "",
      password: "",
      password_one_collab:"",
      showpassword: false,
      showpassword_register: false,
      showconfirmpassword_register: false,
      showpassword_one_collab: false,
      username_register: "",
      password_register: "",
      confirmpassword_register: "",
      phonenumber: "",
      email: "",
      email_one_collab:"",
      check_easylogin: false,
      check_button_login: false,
      check_show_alert: "",
      isDesktopsize: true,
      isMobilesize: false,
      urloneid: process.env.VUE_APP_ONEID,
      opendialognewstorage: false,
      checkbuttonalert: true,
      dialogSessionExpired: false,
      requiredPhoneNumber: [
        // (value) => !!value || "โปรดระบุข้อมูล.",
        // (value) => (value && value.length == 10 && this.fn_checkphonenumber(value)) || "โปรดระบุตัวเลข 10 หลัก / Please fill 10 digits",
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) =>
          (value && value.length == 10 && this.fn_checkphonenumber(value)) ||
          "โปรดระบุตัวเลข 10 หลัก / Please fill 10 digits",
      ],
      requiredusername: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) =>
          (/^(?=.*[a-z])/.test(value) &&
            value.length >= 6 &&
            this.fn_checkusername(value)) ||
          "ตัวอักษรอย่างน้อย 6 ตัว และห้ามมีอักขระพิเศษ / Must have at least 6 characters",
      ],
      requiredpassword: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) =>
          (/^(?=.*[A-Za-z])(?=.*\d).{8,}$/.test(value) &&
            this.fn_checkSpecialChar_(value)) ||
          "ต้องมีตัวเลข และตัวอักษรภาษาอังกฤษ อย่างน้อย 1 ตัว ความยาวอย่างน้อย 8 / Must have at least 8 characters",
      ],
      requiredconfirmpassword: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) =>
          this.checkpass(value) || "รหัสผ่านไม่ตรงกัน / Passwords do not match",
      ],
    };
  },
  watch: {
    // failedLogin () {
    //   this.checkFailLogin()
    // }
  },
  computed: {
    ...mapGetters({
      dataBusinessProfile: "dataBusinessProfile",
      dataAccesstoken: "dataAccesstoken",
      dataLoginDefault: "dataLoginDefault",
      dataAccountActive: "dataAccountActive",
      dataUsername: "dataUsername",
      dataAccountId: "dataAccountId",
    }),
    getcheck() {
      let check = localStorage.getItem("show");
      if (check === "true") {
        return true;
      } else {
        return false;
      }
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    phonenumberError() {
      const errors = [];
      if (!this.$v.phonenumber.$dirty) return errors;
      !this.$v.phonenumber.required &&
        errors.push("โปรดระบุเบอร์โทร / Please fill Mobile No.");
      return errors;
    },
    usernameError() {
      const errors = [];
      if (!this.$v.username_register.$dirty) return errors;
      !this.$v.username_register.required &&
        errors.push("โปรดระบุข้อมูลชื่อผู้ใช้ / Please fill Username");
      return errors;
    },
    passwordError() {
      const errors = [];
      if (!this.$v.password_register.$dirty) return errors;
      !this.$v.password_register.required &&
        errors.push(
          "โปรดระบุรหัสผ่าน / Please fill Password / Must have at least 8 characters "
        );
      return errors;
    },
    confirmpasswordError() {
      const errors = [];
      if (!this.$v.confirmpassword_register.$dirty) return errors;
      !this.$v.confirmpassword_register.required &&
        errors.push(
          "โปรดยืนยันรหัสผ่าน / Please fill Password / Must have at least 8 characters "
        );
      return errors;
    },
    usernameloginError() {
      const errors = [];
      if (!this.$v.username.$dirty) return errors;
      !this.$v.username.required &&
        errors.push("โปรดระบุชื่อผู้ใช้ / Please fill Username");
      return errors;
    },
    passwordloginError() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required &&
        errors.push("โปรดระบุรหัสผ่าน / Please fill Password");
      return errors;
    },
    idcardloginError() {
      const errors = [];
      if (!this.$v.idcard.$dirty) return errors;
      !this.$v.idcard.required &&
        errors.push("โปรดระบุเลขบัตรประชาชน / Please fill ID Card");
      return errors;
    },
    convertTime() {
      const hours = ~~(this.countDownFailedLogin / 3600);
      const mins = ~~((this.countDownFailedLogin % 3600) / 60);
      const secs = ~~this.countDownFailedLogin % 60;
      let time = "";
      time += hours > 0 ? ` ${hours} ${this.$t("landingpage.hour")} ` : "";
      time += mins > 0 ? ` ${mins} ${this.$t("landingpage.minute")} ` : "";
      time += ` ${secs} ${this.$t("landingpage.second")} `;
      return time;
    },
    formattedCountdownLoginSMS() {
      const mins = ~~(this.countDown / 60);
      const secs = this.countDown % 60;
      return `${this.padTime(mins)}:${this.padTime(secs)}`;
    },
  },
  methods: {
    onKeyDown() {
      // current pressed key
      var pressedKey = String.fromCharCode(event.keyCode).toLowerCase();

      if (event.ctrlKey && (pressedKey == "c" || pressedKey == "v")) {
        // disable key press porcessing
        event.returnValue = false;
      }
    },
    fn_tolowercase() {
      this.username = this.username.toLowerCase();
      this.username_register = this.username_register.toLowerCase();
    },
    onScroll() {
      const showfooter = window.innerWidth;
      this.isDesktopsize = showfooter > 600;
      this.isMobilesize = showfooter < 600;
    },
    close_dialog() {
      if (
        localStorage.getItem("checkshow") === "false" ||
        localStorage.getItem("checkshow") === undefined ||
        localStorage.getItem("checkshow") === null
      ) {
        this.opendialognewstorage = true;
        this.checkbuttonalert = true;
      } else {
        this.opendialognewstorage = false;
        this.checkbuttonalert = true;
      }
    },
    fn_register_mohpromt() {
      this.$router.push("/register_mohpromt2");
    },
    login() {
      console.log("this.checklogin_cookie",this.checklogin_cookie);
      console.log("this.tab_idcard",this.tab_idcard);
      if (this.user_id !== "" && this.user_id !== null) {
        this.user_id = CryptoJS.AES.decrypt(this.user_id, CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
        this.user_id = window.atob(this.user_id.toString(CryptoJS.enc.Utf8));
      }
      this.loading = true;
      if (this.checklogin_cookie == "true") {
        this.check_memory = true;
        this.$store
          .dispatch("authorize_checking", this.user_id)
          .then((msg) => {
            // console.log(msg);
            this.loading = false;
            if (msg.status === "Access Granted") {
              
              console.log("this.check_button_login", this.check_button_login);
              if (this.check_memory == false) {
                let userid = VueCookies.get("user_id");
                // let userid = localStorage.getItem("user_id");
                let token = VueCookies.get("token");
                sessionStorage.setItem("user_id", userid);
                sessionStorage.setItem("checklogin", false);
                sessionStorage.setItem("token", token, 60 * 60 * 24);
                sessionStorage.setItem("token_auth", true, 60 * 60 * 24);
                // VueCookies.remove("token");
                // VueCookies.remove("token_auth");
                localStorage.removeItem("user_id");
                VueCookies.set("checklogin", false, 60 * 60 * 24);
                // VueCookies.remove("user_en");
                // VueCookies.set("SessionExpired", false);
                // VueCookies.set("test", false);
              } else {
                VueCookies.set("checklogin", true, 60 * 60 * 24);
                // VueCookies.set("user_en", encodeduserpass, 60 * 60 * 24);
              }
            //console.log("this.dataLoginDefault",this.dataLoginDefault,this.dataLoginDefault["account_category"]); 
            if(this.dataLoginDefault !== null){
              if(this.dataLoginDefault !== ""){   
                console.log("เข้า if"); 
                if(this.dataLoginDefault["account_category"] === "Business"){
                  console.log(this.dataLoginDefault["account_category"]);
                  this.loginbusiness_default();
                } else {
                  this.$router.push("/mydrive");
                }
              } else {
                this.$router.push("/mydrive");
              }
            } else {
              this.$router.push("/mydrive");
            }
            // if ("goto" in this.$route.query) {
            //   this.loginbusiness(
            //     this.$route.query.goto,
            //     this.$route.query.taxid
            //   );
            // } else {
            //   this.$router.push("/mydrive");
            // }
          } else if (msg.code === "LN001") {
            Toast.fire({
              icon: "error",
              // title: "Username หรือ Password ไม่ถูกต้อง"
              title: this.$t('landingpage.notiloginandregister.incorrectusernameorpassword')
            });
          } else if (msg.code === "ER401") {
            Toast.fire({
              icon: "error",
              // title: "Username หรือ Password ไม่ถูกต้อง"
              title: this.$t('landingpage.notiloginandregister.incorrectusernameorpassword')
            });
          } else if (msg.msg === "ER401: Authorization is wrong") {
            Toast.fire({
              icon: "error",
              // title: "Username หรือ Password ไม่ถูกต้อง"
              title: this.$t('landingpage.notiloginandregister.incorrectusernameorpassword')
            });
            this.loader = false;
          } else if(msg.msg === "ER401: The resource owner or authorization server denied the"){
            Toast.fire({ 
                icon: "error",
                // title: msg.msg
                // title: this.$t('toast.cannotconnectonebox')
                title: this.$t('toast.expired_token')
              });
              this.logout();

          }else {
            console.log("เข้าอันนี้");
              Toast.fire({ 
                icon: "error",
                // title: msg.msg
                // title: this.$t('toast.cannotconnectonebox')
                title: this.$t("toast.expired_token"),
              });
              this.logout();
            }
          })
          .catch((err) => {
            console.log(err);
            Toast.fire({
              icon: "error",
              // title: "ไม่สามารถเชื่อมต่อ OneBox ได้ กรุณาติดต่อผู้ดูแลระบบ "
              title: this.$t("toast.cannotconnectonebox"),
            });
            this.loading = false;
          });
      } else {
        // check validate
        if (((this.$v.username.$invalid || this.$v.password.$invalid) && this.tab_username && !this.tab_one_collab) || 
            ((this.$v.idcard.$invalid || this.$v.password.$invalid) && this.tab_idcard && !this.tab_one_collab)) {
          this.$v.$touch();
          this.loading = false;
          return
        }

        let payload;
        VueCookies.set("check_btn_login_new", true);
        console.log("check_button_login**", this.check_button_login);
        console.log("login_test");
        this.loading = true;

        let apiPathLogin = 'authorize_login_nooneid'
        let loginType = "username"
        if (this.tab_username) {
          VueCookies.set("check_mohpromt", false);
          console.log("เข้า1");
          let usernamepass = {
            username: this.username,
            password: this.password,
            login_type: "username",
          };

          // var encodedString = btoa(usernamepass);
          // encode username password
          var encodeduserpass = btoa(JSON.stringify(usernamepass));
          console.log("encoded", encodeduserpass);
          console.log("++++++", VueCookies.get("user_en"));

          if (
            VueCookies.get("user_en") === null ||
            VueCookies.get("user_en") === ""
          ) {
            payload = {
              data: encodeduserpass,
            };
          } else {
            payload = {
              data: VueCookies.get("user_en"),
            };
          }
          // decode กลับ
          var actual = JSON.parse(atob(encodeduserpass));
          console.log("actual", actual);
        }
        if (this.tab_idcard) {
          VueCookies.set("check_mohpromt", true);
          payload = {
            username: this.idcard,
            password: this.password,
            login_type: "id_card",
          };
          VueCookies.set("id_card_num", this.idcard);
          var encodeduserpass_ = btoa(JSON.stringify(payload));
          payload = {
            data: encodeduserpass_,
          };

          console.log("payload-->", payload);
          loginType = "idcard"

          // // decode กลับ
          // var actual = JSON.parse(atob(encodeduserpass))
          // console.log("actual",actual);
        }
        if (this.tab_sms) {
          payload = {
            mobile_no: this.phonenumber,
            otp: this.otp,
          };
          console.log("payload SMS", payload);
          apiPathLogin = 'authorize_login_mobile_otp'
          loginType = "sms"
        }
        if (this.tab_one_collab) {
          payload = {
            email : this.email_one_collab,
            password : this.password_one_collab
          };
          apiPathLogin = 'authorize_onecollab_email'
          loginType = "onecollab"
        }
        this.$store
          .dispatch(apiPathLogin, payload)
          .then((msg) => {
            console.log("msg",msg);
            this.loading = false;
            if (msg.status === "Access Granted") {
              // ส่ง event ไปยัง Google Analytics
              this.$gtag.event('login', {
                event_category: loginType,
              });

              // localStorage.setItem("loginType", loginType);
              VueCookies.set("loginType", loginType);
              // this.$router.push("/mydrive");
              console.log("this.check_button_login", this.check_button_login);
              if (this.check_memory == false) {
                // let userid = localStorage.getItem("user_id");
                let userid = VueCookies.get("user_id");
                let token = VueCookies.get("token");
                sessionStorage.setItem("user_id", userid);
                sessionStorage.setItem("checklogin", false);
                sessionStorage.setItem("token", token, 60 * 60 * 24);
                sessionStorage.setItem("token_auth", true, 60 * 60 * 24);
                // VueCookies.remove("token");
                // VueCookies.remove("token_auth");
                // localStorage.removeItem("user_id");
                VueCookies.set("checklogin", false, 60 * 60 * 24);
                // VueCookies.remove("user_en");
                // localStorage.removeItem("failedLogin");
                // localStorage.removeItem("timeFailedLogin");
                // VueCookies.set("SessionExpired", false);
                // VueCookies.set("test", false);
              } else {
                VueCookies.set("checklogin", true, 60 * 60 * 24);
                VueCookies.set("user_en", encodeduserpass, 60 * 60 * 24);
              }
              console.log(
                "this.dataLoginDefault",
                this.dataLoginDefault,
                this.dataLoginDefault["account_category"]
              );

              if (this.dataLoginDefault !== "") {
                console.log("เข้า if");
                if (this.dataLoginDefault["account_category"] === "Business") {
                  console.log(this.dataLoginDefault["account_category"]);
                  this.loginbusiness_default();
                } else {
                  this.$router.push("/mydrive");
                }
              } else {
                 this.$router.push("/mydrive");
              }
              if ("goto" in this.$route.query) {
                this.loginbusiness(
                  this.$route.query.goto,
                  this.$route.query.taxid
                );
              } else if ("nextUrl" in this.$route.query) {
                this.$router.push(this.$route.query.nextUrl);
              // } else {
              //    this.$router.push("/mydrive");
              // }
              }
            } else if (msg.code === "LN001") {
              Toast.fire({
                icon: "error",
                // title: "Username หรือ Password ไม่ถูกต้อง"
                title: this.$t(
                  "landingpage.notiloginandregister.incorrectusernameorpassword"
                ),
              });
            } else if (msg.code === "ER401") {
              // this.failedLogin++;
              // localStorage.setItem('failedLogin', this.failedLogin);
              // localStorage.setItem('timeFailedLogin', new Date().getTime());
              Toast.fire({
                icon: "error",
                // title: "Username หรือ Password ไม่ถูกต้อง"
                title: this.$t(
                  "landingpage.notiloginandregister.incorrectusernameorpassword"
                ),
              });
            } else if (msg.msg === "ER401: Authorization is wrong") {
              Toast.fire({
                icon: "error",
                // title: "Username หรือ Password ไม่ถูกต้อง"
                title: this.$t(
                  "landingpage.notiloginandregister.incorrectusernameorpassword"
                ),
              });
              this.loader = false;
            } else if (msg.code === "ER001") {
              Toast.fire({
                icon: "error",
                // title: "Username หรือ Password ไม่ถูกต้อง"
                title: this.$t(
                  "landingpage.notiloginandregister.emptyusernameorpassword"
                ),
              });
              this.loader = false;
            } else if (msg.code === "ER002") {              
              Toast.fire({
                icon: "error",
                title: this.$t("toast.otpMismatch"),
              });
              this.loader = false;
            } else if (msg.code === "ER304") {
              Toast.fire({
                icon: "error",
                title: this.$t("landingpage.notiloginandregister.incorrectlogin"),
              });
              this.loader = false;
              this.checkFailLogin(msg.msg);
            } else if(msg.code === "ER100"){
              // console.log("100",msg);
              // Toast.fire({
              //   icon: "error",
              //   title: msg.error_data.errorMessage,
              // });
              this.data_password_expired = msg.error_data;
              this.opendialogalertpasswordexpired = true;
            } else if(msg.code === "ER103"){
              this.data2fa = msg.error_data;
              this.opendialog2faerror103 = true;
            }else if(msg.code === "ER104"){
              this.data2fa = msg.error_data;
              console.log("this.data2fa",this.data2fa);
              this.opendialog2faotp = true;
              // this.opendialog2faerror103 = true;
            }else if(msg.code === "ER105"){
              Toast.fire({
                icon: "error",
                title: this.$t("otp_botonebox.invalid_otp"),
              });
            }else{
              Toast.fire({
                icon: "error",
                // title: msg.msg
                title: this.$t("toast.cannotconnectonebox"),
              });
            }
          })
          .catch((err) => {
            console.log(err);
            Toast.fire({
              icon: "error",
              // title: "ไม่สามารถเชื่อมต่อ OneBox ได้ กรุณาติดต่อผู้ดูแลระบบ"
              title: this.$t("toast.cannotconnectonebox"),
            });
            this.loading = false;
          });        
      }
    },
    login2fa(otp2fa,refcode_re) {
      console.log("refcode_re",refcode_re);
      console.log("otp2fa",otp2fa);
      console.log("this.checklogin_cookie",this.checklogin_cookie);
      console.log("this.tab_idcard",this.tab_idcard);
      if (this.user_id !== "" && this.user_id !== null) {
        this.user_id = CryptoJS.AES.decrypt(this.user_id, CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
        this.user_id = window.atob(this.user_id.toString(CryptoJS.enc.Utf8));
      }
      this.loading = true;
      if (this.checklogin_cookie == "true") {
        this.check_memory = true;
        this.$store
          .dispatch("authorize_checking", this.user_id)
          .then((msg) => {
            // console.log(msg);
            this.loading = false;
            if (msg.status === "Access Granted") {
              console.log("this.check_button_login", this.check_button_login);
              if (this.check_memory == false) {
                // let userid = localStorage.getItem("user_id");
                let userid = VueCookies.get("user_id");
                let token = VueCookies.get("token");
                sessionStorage.setItem("user_id", userid);
                sessionStorage.setItem("checklogin", false);
                sessionStorage.setItem("token", token, 60 * 60 * 24);
                sessionStorage.setItem("token_auth", true, 60 * 60 * 24);
                // VueCookies.remove("token");
                // VueCookies.remove("token_auth");
                localStorage.removeItem("user_id");
                VueCookies.set("checklogin", false, 60 * 60 * 24);
                // VueCookies.remove("user_en");
                // VueCookies.set("SessionExpired", false);
                // VueCookies.set("test", false);
              } else {
                VueCookies.set("checklogin", true, 60 * 60 * 24);
                // VueCookies.set("user_en", encodeduserpass, 60 * 60 * 24);
              }
            //console.log("this.dataLoginDefault",this.dataLoginDefault,this.dataLoginDefault["account_category"]); 
            if(this.dataLoginDefault !== null){
              if(this.dataLoginDefault !== ""){   
                console.log("เข้า if"); 
                if(this.dataLoginDefault["account_category"] === "Business"){
                  console.log(this.dataLoginDefault["account_category"]);
                  this.loginbusiness_default();
                } else {
                  this.$router.push("/mydrive");
                }
              } else {
                this.$router.push("/mydrive");
              }
            } else {
              this.$router.push("/mydrive");
            }
            // if ("goto" in this.$route.query) {
            //   this.loginbusiness(
            //     this.$route.query.goto,
            //     this.$route.query.taxid
            //   );
            // } else {
            //   this.$router.push("/mydrive");
            // }
          } else if (msg.code === "LN001") {
            Toast.fire({
              icon: "error",
              // title: "Username หรือ Password ไม่ถูกต้อง"
              title: this.$t('landingpage.notiloginandregister.incorrectusernameorpassword')
            });
          } else if (msg.code === "ER401") {
            Toast.fire({
              icon: "error",
              // title: "Username หรือ Password ไม่ถูกต้อง"
              title: this.$t('landingpage.notiloginandregister.incorrectusernameorpassword')
            });
          } else if (msg.msg === "ER401: Authorization is wrong") {
            Toast.fire({
              icon: "error",
              // title: "Username หรือ Password ไม่ถูกต้อง"
              title: this.$t('landingpage.notiloginandregister.incorrectusernameorpassword')
            });
            this.loader = false;
          } else if(msg.msg === "ER401: The resource owner or authorization server denied the"){
            Toast.fire({ 
                icon: "error",
                // title: msg.msg
                // title: this.$t('toast.cannotconnectonebox')
                title: this.$t('toast.expired_token')
              });
              this.logout();

          }else {
            console.log("เข้าอันนี้");
              Toast.fire({ 
                icon: "error",
                // title: msg.msg
                // title: this.$t('toast.cannotconnectonebox')
                title: this.$t("toast.expired_token"),
              });
              this.logout();
            }
          })
          .catch((err) => {
            console.log(err);
            Toast.fire({
              icon: "error",
              // title: "ไม่สามารถเชื่อมต่อ OneBox ได้ กรุณาติดต่อผู้ดูแลระบบ "
              title: this.$t("toast.cannotconnectonebox"),
            });
            this.loading = false;
          });
      } else {
        // check validate
        if (((this.$v.username.$invalid || this.$v.password.$invalid) && this.tab_username && !this.tab_one_collab) || 
            ((this.$v.idcard.$invalid || this.$v.password.$invalid) && this.tab_idcard && !this.tab_one_collab)) {
          this.$v.$touch();
          this.loading = false;
          return
        }

        let payload;
        VueCookies.set("check_btn_login_new", true);
        console.log("check_button_login**", this.check_button_login);
        console.log("login_test");
        this.loading = true;

        let apiPathLogin = 'authorize_login_nooneid'
        let loginType = "username"
        if (this.tab_username) {
          VueCookies.set("check_mohpromt", false);
          console.log("เข้า1");
          let usernamepass = {
            username: this.username,
            password: this.password,
            login_type: "username",
            otp: otp2fa,
            refcode: refcode_re.refcode,
          };

          // var encodedString = btoa(usernamepass);
          // encode username password
          var encodeduserpass = btoa(JSON.stringify(usernamepass));
          console.log("encoded", encodeduserpass);
          console.log("++++++", VueCookies.get("user_en"));

          if (
            VueCookies.get("user_en") === null ||
            VueCookies.get("user_en") === ""
          ) {
            payload = {
              data: encodeduserpass,
            };
          } else {
            payload = {
              data: VueCookies.get("user_en"),
            };
          }
          // decode กลับ
          var actual = JSON.parse(atob(encodeduserpass));
          console.log("actual", actual);
        }
        if (this.tab_idcard) {
          VueCookies.set("check_mohpromt", true);
          payload = {
            username: this.idcard,
            password: this.password,
            login_type: "id_card",
          };
          VueCookies.set("id_card_num", this.idcard);
          var encodeduserpass_ = btoa(JSON.stringify(payload));
          payload = {
            data: encodeduserpass_,
          };

          console.log("payload-->", payload);
          loginType = "idcard"

          // // decode กลับ
          // var actual = JSON.parse(atob(encodeduserpass))
          // console.log("actual",actual);
        }
        if (this.tab_sms) {
          payload = {
            mobile_no: this.phonenumber,
            otp: this.otp,
          };
          console.log("payload SMS", payload);
          apiPathLogin = 'authorize_login_mobile_otp'
          loginType = "sms"
        }
        if (this.tab_one_collab) {
          payload = {
            email : this.email_one_collab,
            password : this.password_one_collab
          };
          apiPathLogin = 'authorize_onecollab_email'
          loginType = "onecollab"
        }
        this.$store
          .dispatch(apiPathLogin, payload)
          .then((msg) => {
            console.log("msg",msg);
            this.loading = false;
            if (msg.status === "Access Granted") {
              // ส่ง event ไปยัง Google Analytics
              this.$gtag.event('login', {
                event_category: loginType,
              });
              
              // localStorage.setItem("loginType", loginType);
              VueCookies.set("loginType", loginType);
              // this.$router.push("/mydrive");
              console.log("this.check_button_login", this.check_button_login);
              if (this.check_memory == false) {
                // let userid = localStorage.getItem("user_id");
                let userid = VueCookies.get("user_id");
                let token = VueCookies.get("token");
                sessionStorage.setItem("user_id", userid);
                sessionStorage.setItem("checklogin", false);
                sessionStorage.setItem("token", token, 60 * 60 * 24);
                sessionStorage.setItem("token_auth", true, 60 * 60 * 24);
                // VueCookies.remove("token");
                // VueCookies.remove("token_auth");
                // localStorage.removeItem("user_id");
                VueCookies.set("checklogin", false, 60 * 60 * 24);
                // VueCookies.remove("user_en");
                // localStorage.removeItem("failedLogin");
                // localStorage.removeItem("timeFailedLogin");
                // VueCookies.set("SessionExpired", false);
                // VueCookies.set("test", false);
              } else {
                VueCookies.set("checklogin", true, 60 * 60 * 24);
                VueCookies.set("user_en", encodeduserpass, 60 * 60 * 24);
              }
              console.log(
                "this.dataLoginDefault",
                this.dataLoginDefault,
                this.dataLoginDefault["account_category"]
              );

              if (this.dataLoginDefault !== "") {
                console.log("เข้า if");
                if (this.dataLoginDefault["account_category"] === "Business") {
                  console.log(this.dataLoginDefault["account_category"]);
                  this.loginbusiness_default();
                } else {
                  this.$router.push("/mydrive");
                }
              } else {
                 this.$router.push("/mydrive");
              }
              if ("goto" in this.$route.query) {
                this.loginbusiness(
                  this.$route.query.goto,
                  this.$route.query.taxid
                );
              } else if ("nextUrl" in this.$route.query) {
                this.$router.push(this.$route.query.nextUrl);
              // } else {
              //    this.$router.push("/mydrive");
              // }
              }
            } else if (msg.code === "LN001") {
              Toast.fire({
                icon: "error",
                // title: "Username หรือ Password ไม่ถูกต้อง"
                title: this.$t(
                  "landingpage.notiloginandregister.incorrectusernameorpassword"
                ),
              });
            } else if (msg.code === "ER401") {
              // this.failedLogin++;
              // localStorage.setItem('failedLogin', this.failedLogin);
              // localStorage.setItem('timeFailedLogin', new Date().getTime());
              Toast.fire({
                icon: "error",
                // title: "Username หรือ Password ไม่ถูกต้อง"
                title: this.$t(
                  "landingpage.notiloginandregister.incorrectusernameorpassword"
                ),
              });
            } else if (msg.msg === "ER401: Authorization is wrong") {
              Toast.fire({
                icon: "error",
                // title: "Username หรือ Password ไม่ถูกต้อง"
                title: this.$t(
                  "landingpage.notiloginandregister.incorrectusernameorpassword"
                ),
              });
              this.loader = false;
            } else if (msg.code === "ER001") {
              Toast.fire({
                icon: "error",
                // title: "Username หรือ Password ไม่ถูกต้อง"
                title: this.$t(
                  "landingpage.notiloginandregister.emptyusernameorpassword"
                ),
              });
              this.loader = false;
            } else if (msg.code === "ER002") {              
              Toast.fire({
                icon: "error",
                title: this.$t("toast.otpMismatch"),
              });
              this.loader = false;
            } else if (msg.code === "ER304") {
              Toast.fire({
                icon: "error",
                title: this.$t("landingpage.notiloginandregister.incorrectlogin"),
              });
              this.loader = false;
              this.checkFailLogin(msg.msg);
            } else if(msg.code === "ER100"){
              // console.log("100",msg);
              // Toast.fire({
              //   icon: "error",
              //   title: msg.error_data.errorMessage,
              // });
              this.data_password_expired = msg.error_data;
              this.opendialogalertpasswordexpired = true;
            } else if(msg.code === "ER103"){
              this.opendialog2faotp = true;
            }else if(msg.code === "ER104"){
              this.data2fa = msg.error_data;
              Toast.fire({
                icon: "error",
                title: localStorage.getItem("lang") === 'en' ? msg.error_data.errorMessage : "กรุณากรอกรหัส OTP"
              });
            }else if(msg.code === "ER105"){
              Toast.fire({
                icon: "error",
                title: this.$t("otp_botonebox.invalid_otp"),
              });
            }else{
              Toast.fire({
                icon: "error",
                // title: msg.msg
                title: this.$t("toast.cannotconnectonebox"),
              });
            }
          })
          .catch((err) => {
            console.log(err);
            Toast.fire({
              icon: "error",
              // title: "ไม่สามารถเชื่อมต่อ OneBox ได้ กรุณาติดต่อผู้ดูแลระบบ"
              title: this.$t("toast.cannotconnectonebox"),
            });
            this.loading = false;
          });        
      }
    },

    encryptdata (raw_data) {
      let enc = CryptoJS.AES.encrypt(window.btoa(raw_data), 
      CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY),{ mode: CryptoJS.mode.ECB, }).toString()
      return enc
    },

    decryptdata (data) {
      var data_decrypt = CryptoJS.AES.decrypt(data, 
          CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
      data_decrypt = window.atob(data_decrypt.toString(CryptoJS.enc.Utf8)) 
      return data_decrypt
    },

    diff_minutes (dt2, dt1) {
      var diff =(dt2 - dt1) / 1000;
      diff /= 60;
      return Math.abs(Math.round(diff))
    },

    
    login_with_botonebox () { // ยังไม่ได้ใช้งาน
      this.loading = true;
      if(this.checklogin_cookie == "true"){
        if(this.user_id !== "" && this.user_id !== null) {
            this.user_id = this.decryptdata(this.user_id);
        }
        this.check_memory = true;
        if(sessionStorage.getItem("checklogin") !== null){ // ไม่จดจำฉัน
          var check_logindatetime = this.decryptdata(VueCookies.get("login_datetime"))
          var timeUsed = this.diff_minutes(new Date().getTime(), new Date(check_logindatetime).getTime())
            if(timeUsed <= 0){ //เวลาที่ใช้งานไปเกินกว่าเวลาที่กำหนด
              Toast.fire({ 
                  icon: "error",
                  title: this.$t("toast.expired_token"),
              });
              this.logout();
              this.loading = false;
              return
            } else {
              this.check_memory = true;
              this.$store
              .dispatch("authorize_checking", this.user_id)
              .then((msg) => {
                this.loading = false;
                if (msg.status === "Access Granted") {
                  if(this.dataLoginDefault !== null){
                    if(this.dataLoginDefault !== ""){   
                      console.log("เข้า if"); 
                      if(this.dataLoginDefault["account_category"] === "Business"){
                        console.log(this.dataLoginDefault["account_category"]);
                        this.loginbusiness_default();
                      } else {
                        this.$router.push("/mydrive");
                      }
                    } else {
                      this.$router.push("/mydrive");
                    }
                  } else {
                    this.$router.push("/mydrive");
                  }
                  if ("goto" in this.$route.query) {
                    this.loginbusiness(
                      this.$route.query.goto,
                      this.$route.query.taxid
                    );
                  } else {
                    this.$router.push("/mydrive");
                  }
                } else if (msg.code === "LN001") {
                  Toast.fire({
                    icon: "error",
                    // title: "Username หรือ Password ไม่ถูกต้อง"
                    title: this.$t('landingpage.notiloginandregister.incorrectusernameorpassword')
                  });
                } else if (msg.code === "ER401") {
                  Toast.fire({
                    icon: "error",
                    // title: "Username หรือ Password ไม่ถูกต้อง"
                    title: this.$t('landingpage.notiloginandregister.incorrectusernameorpassword')
                  });
                } else if (msg.msg === "ER401: Authorization is wrong") {
                  Toast.fire({
                    icon: "error",
                    // title: "Username หรือ Password ไม่ถูกต้อง"
                    title: this.$t('landingpage.notiloginandregister.incorrectusernameorpassword')
                  });
                  this.loader = false;
                } else if(msg.msg === "ER401: The resource owner or authorization server denied the"){
                  Toast.fire({ 
                      icon: "error",
                      // title: msg.msg
                      // title: this.$t('toast.cannotconnectonebox')
                      title: this.$t('toast.expired_token')
                    });
                    this.logout();

                }else {
                  console.log("เข้าอันนี้");
                    Toast.fire({ 
                      icon: "error",
                      // title: msg.msg
                      // title: this.$t('toast.cannotconnectonebox')
                      title: this.$t("toast.expired_token"),
                    });
                    this.logout();
                }
              })
              .catch((err) => {
                  Toast.fire({
                    icon: "error",
                    // title: "ไม่สามารถเชื่อมต่อ OneBox ได้ กรุณาติดต่อผู้ดูแลระบบ "
                    title: this.$t("toast.cannotconnectonebox"),
                  });
                  this.loading = false;
                });
            }
          
        } else { // จดจำฉัน
          console.log("Remember me")
          if(VueCookies.isKey("token_auth") === true){ // login ในขณะที่ยังอยู่ใยเวลา
            this.$store
              .dispatch("authorize_checking", this.user_id)
              .then((msg) => {
                this.loading = false;
                if (msg.status === "Access Granted") {
                  if(this.dataLoginDefault !== null){
                    if(this.dataLoginDefault !== ""){   
                      if(this.dataLoginDefault["account_category"] === "Business"){
                        console.log(this.dataLoginDefault["account_category"]);
                        this.loginbusiness_default();
                      } else {
                        this.$router.push("/mydrive");
                      }
                    } else {
                      this.$router.push("/mydrive");
                    }
                  } else {
                    this.$router.push("/mydrive");
                  }
                  if ("goto" in this.$route.query) {
                    this.loginbusiness(
                      this.$route.query.goto,
                      this.$route.query.taxid
                    );
                  } else {
                    this.$router.push("/mydrive");
                  }
                } else if (msg.code === "LN001") {
                  Toast.fire({
                    icon: "error",
                    // title: "Username หรือ Password ไม่ถูกต้อง"
                    title: this.$t('landingpage.notiloginandregister.incorrectusernameorpassword')
                  });
                } else if (msg.code === "ER401") {
                  Toast.fire({
                    icon: "error",
                    // title: "Username หรือ Password ไม่ถูกต้อง"
                    title: this.$t('landingpage.notiloginandregister.incorrectusernameorpassword')
                  });
                } else if (msg.msg === "ER401: Authorization is wrong") {
                  Toast.fire({
                    icon: "error",
                    // title: "Username หรือ Password ไม่ถูกต้อง"
                    title: this.$t('landingpage.notiloginandregister.incorrectusernameorpassword')
                  });
                  this.loader = false;
                } else if(msg.msg === "ER401: The resource owner or authorization server denied the"){
                  Toast.fire({ 
                      icon: "error",
                      // title: msg.msg
                      // title: this.$t('toast.cannotconnectonebox')
                      title: this.$t('toast.expired_token')
                    });
                    this.logout();

                }else {
                  console.log("เข้าอันนี้");
                    Toast.fire({ 
                      icon: "error",
                      // title: msg.msg
                      // title: this.$t('toast.cannotconnectonebox')
                      title: this.$t("toast.expired_token"),
                    });
                    this.logout();
                }
              })
              .catch((err) => {
                  Toast.fire({
                    icon: "error",
                    // title: "ไม่สามารถเชื่อมต่อ OneBox ได้ กรุณาติดต่อผู้ดูแลระบบ "
                    title: this.$t("toast.cannotconnectonebox"),
                  });
                  this.loading = false;
              });
          
          } else { // login ไม่ได้อยู่ในเวลา
            Toast.fire({ 
              icon: "error",
              title: this.$t("toast.expired_token"),
            });
            
            this.logout();
            return
          }
        }

      } else {
        let payload;
        let apiPathLogin = 'authorize_login_nooneid_with_botonebox'
        let loginType = "username"
        if (this.tab_username) {
          //VueCookies.set("check_mohpromt", false);
          let usernamepass = {
            username: this.username,
            password: this.password,
            login_type: "username",
          };
          var encodeduserpass = btoa(JSON.stringify(usernamepass));
          if (VueCookies.get("user_en") === null || VueCookies.get("user_en") === "") {
            payload = {
              data: encodeduserpass,
            };
          } else {
            payload = {
              data: VueCookies.get("user_en"),
            };
          }
          // decode กลับ
          var actual = JSON.parse(atob(encodeduserpass));
          console.log("actual", actual); 
        }
        this.$store
          .dispatch(apiPathLogin, payload)
          .then((msg) => {
            // console.log(msg);
            this.loading = false;
            if (msg.status === "Access Granted") {
              // ส่ง event ไปยัง Google Analytics
              this.$gtag.event('login', {
                event_category: loginType,
              });

              // localStorage.setItem("loginType", loginType);
              VueCookies.set("loginType", loginType);
              //this.$router.push("/mydrive");
              if (this.check_memory == false) {
                // let userid = localStorage.getItem("user_id");
                let userid = VueCookies.get("user_id");
                let token = localStorage.getItem("token")
                sessionStorage.setItem("user_id", userid);
                sessionStorage.setItem("checklogin", false);
                sessionStorage.setItem("token", token );
                sessionStorage.setItem("token_auth", true);
                VueCookies.set("continue_otp" , false)
                
              } else {
                let token = localStorage.getItem("token"); // ไม่ได้ใช้งาน
                VueCookies.set("token", token);
                VueCookies.set('token_auth', true, 60 * 60 );
                VueCookies.set("checklogin", true, 60 * 60 * 24);
                VueCookies.set("user_en", encodeduserpass, 60 * 60 * 24);
                VueCookies.set("continue_otp" , false)
              }
              
              if (this.dataLoginDefault !== "") {
                console.log("เข้า if");
                if (this.dataLoginDefault["account_category"] === "Business") {
                  console.log(this.dataLoginDefault["account_category"]);
                  this.loginbusiness_default();
                } else {
                  this.$router.push("/mydrive");
                }
              } else {
                this.$router.push("/mydrive");
              }
              if ("goto" in this.$route.query) {
                this.loginbusiness(
                  this.$route.query.goto,
                  this.$route.query.taxid
                );
              } else if ("nextUrl" in this.$route.query) {
                this.$router.push(this.$route.query.nextUrl);
              } else {
                this.$router.push("/mydrive");
              }
            } else if (msg.code === "LN001") {
              Toast.fire({
                icon: "error",
                title: this.$t(
                  "landingpage.notiloginandregister.incorrectusernameorpassword"
                ),
              });
            } else if (msg.code === "ER401") {
              Toast.fire({
                icon: "error",
                title: this.$t(
                  "landingpage.notiloginandregister.incorrectusernameorpassword"
                ),
              });
            } else if (msg.msg === "ER401: Authorization is wrong") {
              Toast.fire({
                icon: "error",
                title: this.$t(
                  "landingpage.notiloginandregister.incorrectusernameorpassword"
                ),
              });
              this.loader = false;
            } else if (msg.code === "ER001") {
              Toast.fire({
                icon: "error",
                title: this.$t(
                  "landingpage.notiloginandregister.emptyusernameorpassword"
                ),
              });
              this.loader = false;
            } else if (msg.code === "ER002") {              
              Toast.fire({
                icon: "error",
                title: this.$t("toast.otpMismatch"),
              });
              this.loader = false;
            } else if (msg.code === "ER304") {
              Toast.fire({
                icon: "error",
                title: this.$t("landingpage.notiloginandregister.incorrectlogin"),
              });
              this.loader = false;
              this.checkFailLogin(msg.msg);
            } else {
              Toast.fire({
                icon: "error",
                title: this.$t("toast.cannotconnectonebox"),
              });
            }
          })
          .catch((err) => {
            console.log(err);
            Toast.fire({
              icon: "error",
              // title: "ไม่สามารถเชื่อมต่อ OneBox ได้ กรุณาติดต่อผู้ดูแลระบบ"
              title: this.$t("toast.cannotconnectonebox"),
            });
            this.loading = false;
          });




      }
    },
    login_with_botonebox_v2 () { // ใข้งานน
      this.loading = true;
        if(this.user_id !== "" && this.user_id !== null) {
            this.user_id = this.decryptdata(this.user_id);
        }
        this.check_memory = true;
        if(sessionStorage.getItem("checklogin") !== null && VueCookies.isKey("login_datetime") === true){ // ไม่จดจำฉัน
          var check_logindatetime = this.decryptdata(VueCookies.get("login_datetime"))
          var timeUsed = this.diff_minutes(new Date().getTime(), new Date(check_logindatetime).getTime())
            if(timeUsed <= 0){ //เวลาที่ใช้งานไปเกินกว่าเวลาที่กำหนด
              this.$store.dispatch("triggle_otp_botonebox_manage", {status: "", show: false})
              Toast.fire({ 
                  icon: "error",
                  title: this.$t("toast.expired_token"),
              });
              this.logout();
              this.loading = false;
              return
            } else { // login ยังอยู่ในเวลา
              this.check_memory = true;
              this.$store
              .dispatch("authorize_checking", this.user_id)
              .then((msg) => {
                this.loading = false;
                if (msg.status === "Access Granted") {
                  if(this.dataLoginDefault !== null){
                    if(this.dataLoginDefault !== ""){
                      if(this.dataLoginDefault["account_category"] === "Business"){
                        this.loginbusiness_default();
                      } else {
                        this.$router.push("/mydrive");
                      }
                    } else {
                      this.$router.push("/mydrive");
                    }
                  } else {
                    this.$router.push("/mydrive");
                  }
                  if ("goto" in this.$route.query) {
                    this.loginbusiness(
                      this.$route.query.goto,
                      this.$route.query.taxid
                    );
                  } else {
                    this.$router.push("/mydrive");
                  }
                } else if (msg.code === "LN001") {
                  Toast.fire({
                    icon: "error",
                    // title: "Username หรือ Password ไม่ถูกต้อง"
                    title: this.$t('landingpage.notiloginandregister.incorrectusernameorpassword')
                  });
                } else if (msg.code === "ER401") {
                  Toast.fire({
                    icon: "error",
                    // title: "Username หรือ Password ไม่ถูกต้อง"
                    title: this.$t('landingpage.notiloginandregister.incorrectusernameorpassword')
                  });
                } else if (msg.msg === "ER401: Authorization is wrong") {
                  Toast.fire({
                    icon: "error",
                    // title: "Username หรือ Password ไม่ถูกต้อง"
                    title: this.$t('landingpage.notiloginandregister.incorrectusernameorpassword')
                  });
                  this.loader = false;
                } else if(msg.msg === "ER401: The resource owner or authorization server denied the"){
                  Toast.fire({ 
                      icon: "error",
                      // title: msg.msg
                      // title: this.$t('toast.cannotconnectonebox')
                      title: this.$t('toast.expired_token')
                    });
                    this.logout();

                }else {
                  console.log("เข้าอันนี้");
                    Toast.fire({ 
                      icon: "error",
                      // title: msg.msg
                      // title: this.$t('toast.cannotconnectonebox')
                      title: this.$t("toast.expired_token"),
                    });
                    this.logout();
                }
              })
              .catch((err) => {
                  Toast.fire({
                    icon: "error",
                    // title: "ไม่สามารถเชื่อมต่อ OneBox ได้ กรุณาติดต่อผู้ดูแลระบบ "
                    title: this.$t("toast.cannotconnectonebox"),
                  });
                  this.loading = false;
                });
            }
          
        } else if (VueCookies.isKey("checklogin") === true && VueCookies.isKey("login_datetime") === true) { // จดจำฉัน
          if(VueCookies.isKey("token_auth") === true){ // login ในขณะที่ยังอยู่ใยเวลา
            this.$store
              .dispatch("authorize_checking", this.user_id)
              .then((msg) => {
                this.loading = false;
                if (msg.status === "Access Granted") {
                  if(this.dataLoginDefault !== null){
                    if(this.dataLoginDefault !== ""){   
                      if(this.dataLoginDefault["account_category"] === "Business"){
                        console.log(this.dataLoginDefault["account_category"]);
                        this.loginbusiness_default();
                      } else {
                        this.$router.push("/mydrive");
                      }
                    } else {
                      this.$router.push("/mydrive");
                    }
                  } else {
                    this.$router.push("/mydrive");
                  }
                  if ("goto" in this.$route.query) {
                    this.loginbusiness(
                      this.$route.query.goto,
                      this.$route.query.taxid
                    );
                  } else {
                    this.$router.push("/mydrive");
                  }
                } else if (msg.code === "LN001") {
                  Toast.fire({
                    icon: "error",
                    // title: "Username หรือ Password ไม่ถูกต้อง"
                    title: this.$t('landingpage.notiloginandregister.incorrectusernameorpassword')
                  });
                } else if (msg.code === "ER401") {
                  Toast.fire({
                    icon: "error",
                    // title: "Username หรือ Password ไม่ถูกต้อง"
                    title: this.$t('landingpage.notiloginandregister.incorrectusernameorpassword')
                  });
                } else if (msg.msg === "ER401: Authorization is wrong") {
                  Toast.fire({
                    icon: "error",
                    // title: "Username หรือ Password ไม่ถูกต้อง"
                    title: this.$t('landingpage.notiloginandregister.incorrectusernameorpassword')
                  });
                  this.loader = false;
                } else if(msg.msg === "ER401: The resource owner or authorization server denied the"){
                  Toast.fire({ 
                      icon: "error",
                      // title: msg.msg
                      // title: this.$t('toast.cannotconnectonebox')
                      title: this.$t('toast.expired_token')
                    });
                    this.logout();

                }else {
                  console.log("เข้าอันนี้");
                    Toast.fire({ 
                      icon: "error",
                      // title: msg.msg
                      // title: this.$t('toast.cannotconnectonebox')
                      title: this.$t("toast.expired_token"),
                    });
                    this.logout();
                }
              })
              .catch((err) => {
                  Toast.fire({
                    icon: "error",
                    // title: "ไม่สามารถเชื่อมต่อ OneBox ได้ กรุณาติดต่อผู้ดูแลระบบ "
                    title: this.$t("toast.cannotconnectonebox"),
                  });
                  this.loading = false;
              });
          
          } else { // login ไม่ได้อยู่ในเวลา
            this.$store.dispatch("triggle_otp_botonebox_manage", {status: "", show: false})
            this.loading = false;
            Toast.fire({ 
              icon: "error",
              title: this.$t("toast.expired_token"),
            });
            this.logout();
            return
          }
        } else {
          this.$store.dispatch("triggle_otp_botonebox_manage", {status: "", show: false})
          this.loading = false;
            Toast.fire({ 
              icon: "error",
              title: this.$t("toast.expired_token"),
            });
            this.logout();
            return
        }
    },

    login_with_botonebox_firsttime () {
      let usernamepass = {
        username: this.username,
        password: this.password,
        login_type: "username",
      };
      var encodeduserpass = btoa(JSON.stringify(usernamepass));
      if (this.check_memory == false) {
            // let userid = localStorage.getItem("user_id");
            let userid = VueCookies.get("user_id");
            // let token = localStorage.getItem("token") เก่า
            let token = VueCookies.get("token")
            sessionStorage.setItem("user_id", userid);
            sessionStorage.setItem("checklogin", false);
            sessionStorage.setItem("token", token );
            sessionStorage.setItem("token_auth", true);
            VueCookies.set("continue_otp" , false)
      } else {
            // let token = localStorage.getItem("token"); เก่า
            let token = VueCookies.get("token")
            VueCookies.set("token", token);
            VueCookies.set('token_auth', true, 60 * 60 );
            VueCookies.set("checklogin", true, 60 * 60 * 24);
            VueCookies.set("user_en", encodeduserpass, 60 * 60 * 24);
            VueCookies.set("continue_otp" , false)
      }
        
        if (this.dataLoginDefault !== "") {
          if (this.dataLoginDefault["account_category"] === "Business") {
            console.log(this.dataLoginDefault["account_category"]);
            this.loginbusiness_default();
          } else {
            this.$router.push("/mydrive");
          }
        } else {
          this.$router.push("/mydrive");
        }
        if ("goto" in this.$route.query) {
          this.loginbusiness( this.$route.query.goto, this.$route.query.taxid );
        } else if ("nextUrl" in this.$route.query) {
          this.$router.push(this.$route.query.nextUrl);
        } else {
           this.$router.push("/mydrive");
        }
    },

    async fn_logout_with_otp () { //ใช้งานอยู่
      let payload = {
        data_id:  VueCookies.get("data_id")
      }
      return await this.axios
      .post(process.env.VUE_APP_SERVICE_AUTHORIZE_CITIZEN + "/api/v1/logout/otp", payload)
      .then((response) => {
        return new Promise((resolve, reject) => {
          if (response.data.status === "OK") {
            setTimeout(() => {
              resolve({ status: "OK", message: "Verified" });
            }, 500);
          } else {
            setTimeout(() => {
              resolve({ status: "INVALID", message: "Invalid" });
            }, 500);
          }
        });
      })
      .catch((err) => {})
    },

    async logout_with_botonebox () { // ใช้งานนอยู่
      this.$store.dispatch("triggle_otp_botonebox_manage", {status: "", show: false}) // clear vuex
      if(sessionStorage.getItem("checklogin") !== null) { // ไม่จดจำฉัน  (เช็คด้วย login_datetime)
        //เช็คเวลา login datetime ว่ามีหรือถูกปก้ไขหรือป่าว
        var check_isExist_login_logindatetime = VueCookies.isKey("login_datetime")
        var check_logindatetime = this.decryptdata(VueCookies.get("login_datetime"))
        if(check_isExist_login_logindatetime === true && check_logindatetime !== "error" && VueCookies.isKey("data_id")) {
          var timeUsed = this.diff_minutes(new Date().getTime(), new Date(check_logindatetime).getTime())
          if(timeUsed <= 0){ // เกินเวลาที่ใช้งานแล้ว ทดสอบด้วยย 56
            this.logout() // logout แบบไม่ยิง api 
          } else {
            await this.fn_logout_with_otp()
            .then((res) => {
              if(res.status === 'OK'){
                this.logout()
              }
            })
          }
        } else {
          this.logout()
        }
      } else { // จดจำฉัน (เช็คด้วย token_auth)
        //เช็คเวลา login datetime ว่ามีหรือถูกปก้ไขหรือป่าว 
        var check_isExist_login_logindatetime = VueCookies.isKey("login_datetime")
        if(check_isExist_login_logindatetime === true){
          var check_logindatetime = this.decryptdata(VueCookies.get("login_datetime"))
          if(check_logindatetime !== "error" && VueCookies.isKey("data_id")){
            if(VueCookies.isKey("token_auth") === true){
              await this.fn_logout_with_otp()
              .then((res) => {
                if(res.status === 'OK'){
                  this.logout()
                  return
                }
              })
            } else {
              this.logout()
            }
          } else {
            this.logout()
          }
        } else {
          this.logout()
        } 
      }
    },

    // MAI TEST 
    async checkAccount() {
      //มี username อยู่แล้ว / มีการ login อยู่แล้ว
      if (VueCookies.get("username") !== null && VueCookies.get("username") !== "" && VueCookies.get("username") !== undefined) {
        this.username_show = this.decryptdata(VueCookies.get("username"))
        if(this.username_show === process.env.VUE_APP_ACCOUNT_BOT){ // เช็ค account "botonebox"
            this.login_with_botonebox_v2()
        } else { //ไม่ใช่ Account "botonebox"
            this.login()
        }
      } else { // login ครั้งแรก
        this.loading = true
        if (this.username === process.env.VUE_APP_ACCOUNT_BOT) {
          if (((this.$v.username.$invalid || this.$v.password.$invalid) && this.tab_username) || 
               ((this.$v.idcard.$invalid || this.$v.password.$invalid) && this.tab_idcard)) {
                this.$v.$touch();
                this.loading = false;
                return
          }
          if(sessionStorage.getItem("checklogin") === null && VueCookies.isKey("checklogin") === false) { //
                let payload
                let usernamepass = {
                  username: this.username,
                  password: this.password,
                  login_type: "username",
                };
                var encodeduserpass = btoa(JSON.stringify(usernamepass));
                if (VueCookies.get("user_en") === null || VueCookies.get("user_en") === "") {
                  payload = {
                    data: encodeduserpass,
                  };
                } else {
                  payload = {
                    data: VueCookies.get("user_en"),
                  };
                }
                this.$store.dispatch('authorize_login_with_botonebox', payload)
                .then((res) => {
                  if(res.status === 'Access Granted'){
                      let loginType = "username"
                    // localStorage.setItem("loginType", loginType);
                    VueCookies.set("loginType", loginType);
                      // ส่ง event ไปยัง Google Analytics
                      this.$gtag.event('login', {
                        event_category: loginType,
                      });
                    
                      this.loading = true
                      this.dialogOtp = true // *show dialog
                      this.showdialogOtp = true 
                      this.infor_granted = res.result
                  } else {
                    if (res.code === "LN001") {
                    Toast.fire({
                      icon: "error",
                      title: this.$t(
                        "landingpage.notiloginandregister.incorrectusernameorpassword"
                      ),
                    });
                    } else if (res.code === "ER401") {
                      Toast.fire({
                        icon: "error",
                        title: this.$t(
                          "landingpage.notiloginandregister.incorrectusernameorpassword"
                        ),
                      });
                    } else if (res.msg === "ER401: Authorization is wrong") {
                      Toast.fire({
                        icon: "error",
                        title: this.$t(
                          "landingpage.notiloginandregister.incorrectusernameorpassword"
                        ),
                      });
                      this.loader = false;
                    } else if (res.code === "ER001") {
                      Toast.fire({
                        icon: "error",
                        title: this.$t(
                          "landingpage.notiloginandregister.emptyusernameorpassword"
                        ),
                      });
                      this.loader = false;
                    } else if (res.code === "ER002") {              
                      Toast.fire({
                        icon: "error",
                        title: this.$t("toast.otpMismatch"),
                      });
                      this.loader = false;
                    } else if (res.code === "ER304") {
                      Toast.fire({
                        icon: "error",
                        title: this.$t("landingpage.notiloginandregister.incorrectlogin"),
                      });
                      this.loader = false;
                      this.checkFailLogin(res.msg);
                    } else {
                      Toast.fire({
                        icon: "error",
                        // title: msg.msg
                        title: this.$t("toast.cannotconnectonebox"),
                      });
                    }
                    this.loading = false
                  }
                })
          }
        } else {
          this.login()
        }

      }
    },
    checkLogoutAccount () {
      if (VueCookies.get("username") !== null && VueCookies.get("username") !== "" && VueCookies.get("username") !== undefined) {
        this.username_show = this.decryptdata(VueCookies.get("username"))
        if(this.username_show === process.env.VUE_APP_ACCOUNT_BOT){
            this.logout_with_botonebox()
        } else {
            this.logout()
        }
      } else {
        this.logout()
      }
    },
    delay_remove () {
      setTimeout(async () => {
        this.dialogOtp = false
      },300)
    },
    async closeDialogOtpVerify () {
      this.loading = false
      this.showdialogOtp = false 
      let clearDialog_ele = await this.delay_remove()  
      clearTimeout(clearDialog_ele)
    },
    delay_remove2fa () {
      setTimeout(async () => {
        this.opendialog2faotp = false
      },300)
    },

    logout() {
      // this.$router.push({ path: "/logout_" });
      VueCookies.remove("token");
      VueCookies.remove("token_auth");
      VueCookies.remove("checklogin");
      VueCookies.remove("check_btn_login_new");
      VueCookies.remove("check_alert_email");
      sessionStorage.removeItem("biz_active");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("token_auth");
      sessionStorage.removeItem("checklogin");
      sessionStorage.removeItem("user_id");
      localStorage.removeItem("user_id");
      localStorage.removeItem("thai_email");
      //localStorage.removeItem("username");
      //localStorage.removeItem("username_otp")
      localStorage.removeItem("token")
      VueCookies.remove("username_otp");
      VueCookies.remove("username");
      VueCookies.remove("user_en");
      VueCookies.remove("continue_otp")
      VueCookies.remove("reason_otp")
      VueCookies.remove("checkpathmoppromt");
      VueCookies.remove("oneplatfrom");
      VueCookies.remove("check_citizen_oneplat");
      VueCookies.remove("feature_onlyoffice");
      VueCookies.remove("login_datetime")
      VueCookies.remove("data_id")
      VueCookies.remove("taxbox_check");
      VueCookies.remove("iconeva");
      this.checkbox_remember = false;
      this.checklogin_cookie = false;
      this.check_memory = false;
      // this.$router.push({ path: "/logoutunconnectOneID" });
      // this.$router.push("logout_unconnectOneID");
    },
    async loginsso(code) {
      let payload = {
        token: code,
      };
      this.loader = true;
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_AUTHORIZE_SSO +
            "/api/login_by_token_oneid",
          payload
        )
        .then((res) => {
          if (res.data.status == "OK") {
            let encodetoken = window.btoa(res.data.result["accesstoken"])
            localStorage.setItem("token", encodetoken); // ไม่ได้ใช้ใช้งานนนน 
            // localStorage.setItem("token", res.data.result["accesstoken"]);
            VueCookies.set("token", res.data.result["accesstoken"]);
            VueCookies.set("token_auth", true);
            this.$store.dispatch("authorize_granted", res.data.result);
            if (this.dataLoginDefault !== "") {
              if (this.dataLoginDefault["account_category"] === "Business") {
                console.log(this.dataLoginDefault["account_category"]);
                this.loginbusiness_default();
              } else {
                this.$router.push("/mydrive");
              }
            } else {
              // this.$cookies.remove('ssocode')
              this.$router.push("/mydrive");
            }
          } else {
            this.$store.dispatch("authorize_denied");
            // this.$cookies.remove('ssocode')
            this.loader = false;
            // this.dialogsso = true
            // this.intervalSSO = setInterval(() => { this.ssocode = this.$cookies.get('ssocode') }, 800);
            Toast.fire({
              icon: "error",
              // title: 'ไม่สามารถเชื่อมต่อ OneBox ได้ กรุณาติดต่อผู้ดูแลระบบ ',
              title: this.$t("toast.cannotconnectonebox"),
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Toast.fire({
            icon: "error",
            // title: 'ไม่สามารถเชื่อมต่อ OneBox ได้ กรุณาติดต่อผู้ดูแลระบบ ',
            title: this.$t("toast.cannotconnectonebox"),
          });
          this.loading = false;
        });
    },
    async fn_check_business_in_domain(){
      console.log("$router",this.$route);
      let auth = await gbfGenerate.generateToken();

      let payload = {
        domain: "https://onebox.evergreen.co.th",
      }
      console.log("payload121212",payload);
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/check_business_in_domain",
          payload, {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status == "OK") {
            console.log("response.data_fn_check_business_in_domain",response.data);
            this.businessIndomain = response.data.data
          } else {
            console.log("response error",response.data);
          }
        })
        .catch((error) => {
          console.log("error gettamplate search", error);
        });
    },
    async loginbusiness_default() {
      // let dataloginbusiness_ = {
      //   business_id: "f04d153beeae498c351a4acd83c9989a",
      //   tax_id: "0107559000061",
      //   first_name_eng: "SiamEast Solutions Public Company Limited",
      //   first_name_th: "สยามอีสต์ โซลูชั่น จำกัด (มหาชน)",
      //   branch_no: "00001"
      // };
      // console.log("this.dataLoginDefault",this.dataLoginDefault);
      // let objIndex_ = this.dataBusinessProfile.findIndex(
      //     (obj) =>
      //       (obj.branch_no === dataloginbusiness_.branch_no) &
      //       (obj.id_card_num === dataloginbusiness_.id_card_num)
      //   );
      // console.log("objIndex_",objIndex_);

      // if(objIndex_ == -1){
      //   this.$router.push("/mydrive");
      // }else{
        await this.fn_check_business_in_domain();
      //   this.dataloginbusiness = [];
      // this.dataloginbusiness.push({
      //   business_id: "d94c3961512a556dcb9db41b871f0eb8",
      //   tax_id: "0105534033699",
      //   first_name_eng: "EVERGREEN CONTAINER TERMINAL",
      //   first_name_th: "เอเวอร์กรีนคอนเทนเนอร์เทอร์มินัล",
      //   branch_no: "00000"
      // });
      console.log("taxid_dataLoginDefault",this.dataLoginDefault.id_card_num);
      console.log("taxid_dataloginbusiness",this.businessIndomain[0].taxid);
      if(this.dataLoginDefault.id_card_num === this.businessIndomain[0].taxid ){
        console.log("ช่อง1");
        let payload;
        console.log("dataUsername", this.dataUsername);
        console.log("dataBusinessProfile", this.dataBusinessProfile);
        console.log("dataAccountActive", this.dataAccountActive);
        console.log("dataLoginDefault", this.dataLoginDefault);
        let objIndex = this.dataBusinessProfile.findIndex(
          (obj) =>
            (obj.branch_no === this.dataLoginDefault.branch_no) &
            (obj.id_card_num === this.dataLoginDefault.id_card_num)
        );

        if (this.dataAccesstoken === "") {
          payload = {
            taxid: this.dataLoginDefault.id_card_num,
            user_id: this.dataUsername,
            biz_id: this.dataLoginDefault.id,
            branch_no: this.dataBusinessProfile[objIndex].branch_no || "00000",
          };
        } else {
          payload = {
            taxid: this.dataLoginDefault.id_card_num,
            accesstoken: this.dataAccesstoken,
            biz_id: this.dataLoginDefault.id,
            branch_no: this.dataBusinessProfile[objIndex].branch_no || "00000",
          };
        }

        this.$store.dispatch("switchaccount_business", payload).then((msg) => {
          if (msg["status"] === "Business Access Denied") {
            console.log("msg", msg);
            if (msg["msg"] === "ER401: Authorization is wrong") {
              this.$router.push("logout");
            } else {
              // this.$store.dispatch("switchaccount_citizen").then(msg => {
              //   this.$emit("loadfile");
              //   this.$emit("callstorage");
              //   this.$router.push({ name: "myfiles" });
              // });
            }
          } else {
            this.$router.push({
              path: "/directory/" + this.dataLoginDefault["my_folder_id"],
            });
            this.fn_addemail_alertstorage();
            //this.$router.push({ name: "myfiles" });
          }
        });
      }else{
        console.log("ช่อง2");
        for (let i = 0; i < this.dataBusinessProfile.length; i++) {
        let dataIndex = this.businessIndomain.findIndex(x => x.taxid === this.dataBusinessProfile[i].id_card_num);
          if(dataIndex != -1){
            let payload;
        console.log("dataUsername", this.dataUsername);
        console.log("dataBusinessProfile", this.dataBusinessProfile);
        console.log("dataAccountActive", this.dataAccountActive);
        console.log("dataLoginDefault", this.dataLoginDefault);
        let objIndex = this.dataBusinessProfile.findIndex(
          (obj) =>
            (obj.branch_no === this.dataBusinessProfile[i].branch_no) &
            (obj.id_card_num === this.dataBusinessProfile[i].id_card_num)
        );

        if (this.dataAccesstoken === "") {
          payload = {
            taxid: this.dataBusinessProfile[i].id_card_num,
            user_id: this.dataUsername,
            biz_id: this.dataBusinessProfile[i].id,
            branch_no: this.dataBusinessProfile[objIndex].branch_no || "00000",
          };
        } else {
          payload = {
            taxid: this.dataBusinessProfile[i].id_card_num,
            accesstoken: this.dataAccesstoken,
            biz_id: this.dataBusinessProfile[i].id,
            branch_no: this.dataBusinessProfile[objIndex].branch_no || "00000",
          };
        }

        this.$store.dispatch("switchaccount_business", payload).then((msg) => {
          if (msg["status"] === "Business Access Denied") {
            console.log("msg", msg);
            if (msg["msg"] === "ER401: Authorization is wrong") {
              this.$router.push("logout");
            } else {
              // this.$store.dispatch("switchaccount_citizen").then(msg => {
              //   this.$emit("loadfile");
              //   this.$emit("callstorage");
              //   this.$router.push({ name: "myfiles" });
              // });
            }
          } else {
            this.$router.push({
              path: "/directory/" + this.dataBusinessProfile[i]["my_folder_id"],
            });
            this.fn_addemail_alertstorage();
            //this.$router.push({ name: "myfiles" });
          }
        });

          }else{
            console.log("logout");
            this.logout()
            this.opendialogchecklogindeducatenobiz = true;
              // Toast.fire({
              //   icon: "error",
              //   // title: 'ไม่สามารถเชื่อมต่อ OneBox ได้ กรุณาติดต่อผู้ดูแลระบบ ',
              //   title: this.$t("คุณไม่สามารถเข้างานใช้ใน Domain นี้ได้ เนื่องจากไม่อยู่ในบริษัท"),
              // });
            // this.$router.push("/mydrive");
          }
        }
      }
      // }

    },
    loginbusiness(path, taxid) {
      let payload;
      this.loading = true;
      console.log("dataUsername", this.dataUsername);
      if (this.dataAccesstoken === "") {
        payload = {
          taxid: taxid,
          data: this.dataUsername,
        };
      } else {
        payload = {
          taxid: taxid,
          accesstoken: this.dataAccesstoken,
        };
      }
      this.$store.dispatch("switchaccount_business", payload).then((msg) => {
        if (msg["msg"] === "ER401: Authorization is wrong") {
          console.log(msg);
          this.loader = false;
        } else {
          const pobj = JSON.stringify(payload);
          //sessionStorage.removeItem(pobj);
          sessionStorage.setItem("biz_active", pobj);
          if (path !== null || path !== undefined || path === "") {
            this.$router.push({
              name: path,
              query: {
                type: 2,
                sourcesystem: "ETAX",
                systemid: this.$route.query.systemid,
                fromdate: this.$route.query.crefromdate,
                todate: this.$route.query.crefromdate,
                fromdatedoc: this.$route.query.docfromdate,
                todatedoc: this.$route.query.doctodate,
                doctype: this.$route.query.doctype,
                doc_no_start: this.$route.query.docnostart,
                doc_no_end: this.$route.query.docnoend,
                filename: this.$route.query.filename,
                fileextension: this.$route.query.filetype,
                sendername: this.$route.query.sellername,
                sellerBranchid: this.$route.query.sellerbranchid,
                sellerTaxid: this.$route.query.sellertaxid,
                receiver_name: this.$route.query.buyername,
                receiverTaxid: this.$route.query.buyertaxid,
                receiverBranchid: this.$route.query.buyerbranchid,
              },
            });
          } else {
            this.$router.push({ name: "myfiles" });
          }
        }
      });
    },
    async fn_addemail_alertstorage() {
      console.log("fn_addemail_alertstorage");
      let payload = {
        account_id: this.dataAccountId,
        business_id: this.dataLoginDefault.business_id,
      };
      console.log("payload fn_addemail_alertstorage", payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_AUTHORIZE_API +
            "/api/check_email_alert_storage",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((res) => {
          if (res.data.status == "OK") {
            console.log("เข้านี่จ้าา", res.data);
            if (res.data.alert === "Y") {
              VueCookies.set("check_alert_email", "Y");
            } else {
              if (res.data.admin_business_status === "True") {
                VueCookies.set("check_alert_email", "N");
              } else {
                VueCookies.set("check_alert_email", "Y");
              }
            }
          } else {
            Toast.fire({
              icon: "error",
              // title:res.data.errorMessage ,
              title: this.$t("toast.cannotconnectonebox"),
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Toast.fire({
            icon: "error",
            // title: 'ไม่สามารถเชื่อมต่อ OneBox ได้ กรุณาติดต่อผู้ดูแลระบบ ',
            title: this.$t("toast.cannotconnectonebox"),
          });
          this.loading = false;
        });
      // this.$store.dispatch("check_email_alert_storagefull", payload).then((msg) => {
      //       if (msg["status"] === "Sucess") {
      //         console.log("เข้าาาาา");

      //       //   console.log("msg", msg);
      //       //   if (msg["msg"] === "ER401: Authorization is wrong") {
      //       //     this.$router.push("logout");
      //       //   } else {
      //       //     // this.$store.dispatch("switchaccount_citizen").then(msg => {
      //       //     //   this.$emit("loadfile");
      //       //     //   this.$emit("callstorage");
      //       //     //   this.$router.push({ name: "myfiles" });
      //       //     // });
      //       //   }
      //       }
      //     });
    },
    fn_loginoneid() {
      location.assign(process.env.VUE_APP_ONEIDSSO);
    },
    fn_register() {
      this.email = "";
      console.log("กดสมัคร");
      // step_one();
      // check validate
      if (!(this.$v.phonenumber.$invalid || this.$v.username_register.$invalid || 
            this.$v.password_register.$invalid || this.$v.confirmpassword_register.$invalid)) {
        if (this.password_register !== this.confirmpassword_register) {
          Toast.fire({
            icon: "error",
            // title: "รหัสผ่านไม่ตรงกัน กรุณายืนยันรหัสผ่านอีกครั้ง",
            title: this.$t(
              "landingpage.notiloginandregister.incorrectpasswordnotmatchregister"
            ),
          });
          this.password_register = "";
          this.confirmpassword_register = "";
        } else if (this.allowcheckuser == false) {
          Toast.fire({
            icon: "error",
            // title: "รูปแบบชื่อผู้ใช้งานไม่ถูกต้อง",
            title: this.$t(
              "landingpage.notiloginandregister.incorrectformetusername"
            ),
          });
          // this.username = '';
        } else if (this.allowcheckpassword == false) {
          Toast.fire({
            icon: "error",
            // title: "รูปแบบรหัสผ่านไม่ถูกต้อง",
            title: this.$t(
              "landingpage.notiloginandregister.incorrectformetpassword"
            ),
          });
        } else {
          console.log("fn_checkusername", this.fn_checkusername);
          // this.createprogress = true;
          let payload = {
            mobile_no: this.phonenumber,
            username: this.username_register,
            password: this.password_register,
            confirm_password: this.confirmpassword_register,
          };
          console.log("payload_register", payload);
          this.axios
            .post(
              process.env.VUE_APP_SERVICE_AUTHORIZE_BUSINESS +
                "/api/register_onebox",
              payload
            )
            .then((response) => {
              console.log("response", response);
              if (response.data.status === "OK") {
                // this.createprogress = false;
                console.log("response ok", response);
                Toast.fire({
                  icon: "success",
                  title: this.$t("register.regissuccess"),
                });
                this.e1 = 2;
                this.email = response.data.result.email;
                // this.checkregistersuccess = true;
              } else {
                Toast.fire({
                  icon: "error",
                  title: response.data.errorMessage,
                  // title: "test"
                });
              }
            })
            .catch((error) => {
              console.log(error);
              Toast.fire({
                icon: "error",
                // title: "ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
                title: this.$t("toast.cannotconnectonebox"),
              });
            });
        }
      } else {
        Toast.fire({
          icon: "error",
          // title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          title: this.$t("landingpage.notiloginandregister.emptyregister"),
        });
      }
    },
    fn_cleardata() {
      this.check_accept = false;
      this.phonenumber = "";
      this.username_register = "";
      this.password_register = "";
      this.confirmpassword_register = "";
    },
    fn_checkphonenumber(value) {
      const pattern = /^0[0-9]{8,9}$/i;
      if (pattern.test(value) == true) {
        console.log("value.length", value.length);
        return true;
      } else {
        return false;
      }
    },
    fn_checkusername(value) {
      console.log("value fn_checkusername", value);
      // const pattern = /^[a-z0-9]+$/;
      const pattern = /^[a-z0-9]*$/;
      //const pattern =  /^(?=.*[a-z])(?=.*[0-9]){8,}/;
      if (pattern.test(value) == true) {
        console.log("checkusername true");
        this.allowcheckuser = true;
        console.log(this.allowcheckuser);
        return true;
      } else {
        console.log("checkusername false");
        this.allowcheckuser = false;
        console.log(this.allowcheckuser);
        return false;
      }
    },
    fn_checkSpecialChar_(value) {
      let allow = true;
      let specialChar = [" ", '"', "'"];

      for (let index = 0; index < specialChar.length; index++) {
        const element = specialChar[index];
        if (value.includes(element)) {
          allow = false;
          break;
        }
      }
      this.allowcheckpassword = allow;
      console.log("allow", allow);
      return allow;
    },
    checkpass(value) {
      if (value === this.password_register) {
        return true;
      } else {
        return false;
      }
    },
    open() {
      this.opendialogoneid = true;
    },
    async sendOTP() {
      // check validate
      if (this.$v.phonenumber.$invalid && this.tab_sms) {
        this.$v.$touch();
        return
      }

      let payload = {
        mobile_no: this.phonenumber,
      };
      console.log("payload mobile_no", payload);
      let auth = await gbfGenerate.generateToken();      
      this.axios
        .post(process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/oauth_otp_login", payload, {
          headers: { Authorization: auth.code },
        })
        .then((res) => {
          if (res.data.status == "OK") {
            console.log("status ok", res.data);
            this.slideVerify = false;
            this.countDownTimer();
          } else if (res.data.errorCode === "ER304") {
            Toast.fire({
              icon: "error",
              title: this.$t("landingpage.notiloginandregister.incorrectlogin"),
            });
            this.modelValue = '0';
            this.checkFailLogin(res.data.errorMessage);
          } else if (res.data.errorMessage == "too many requests.") {
            console.log("error too many requests.", res.data);
            Toast.fire({
              icon: "error",
              title: this.$t("toast.tooManyRequests"),
            });
            // this.modelValue = '0';
          } else {
            // this.failedLogin++;
            // localStorage.setItem('failedLogin', this.failedLogin);
            // localStorage.setItem('timeFailedLogin', new Date().getTime());
            console.log("error Mobile_no not found", res.data);
            Toast.fire({
              icon: "error",
              title: this.$t('toast.mobileNumberIncorrect'),
            });
            // this.modelValue = '0';
          }
        })
        .catch((err) => {
          console.log('err', err);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
          // this.modelValue = '0';
        });
    },
    countDownTimer() {
      this.countDown = 5 * 60;
      var interval = setInterval(() => {
        if (this.countDown === 0) {
          clearInterval(interval)
          this.isActiveResendOTP = true;
        } else {
          this.countDown--
          this.isActiveResendOTP = false;
        }
      }, 1000)
    },
    padTime(time) { 
      //ถ้าเวลาเป็นเลขหลักเดียว จะเติม 0 ข้างหน้า
      return (time < 10 ? '0' : '') + time;
    },
    onCompleteOTP(otp) {
      this.isActiveConfirmOTP = true
      this.otp = otp
    },
    onChangeOTP(otp) {
      this.isActiveConfirmOTP = false
      this.otp = otp
    },
    loginAzureID() {    
      this.loading_azure = true;

      if (VueCookies.get("azure") !== "1" || !VueCookies.get("token") || VueCookies.get("token_auth") !== "true") {
        // window.location.assign("https://authorized.one.th/verify/business?redirect_url=http://localhost:8080/login/bypass-business?sharetoken=<shared-token>%26biz_id=<business-id>&token_type=shared_token&client_id=26")
        window.location.assign(process.env.VUE_APP_LOGIN_AD);
        // window.location.assign(process.env.VUE_APP_ONEPLATFORM + "/azure/login?service_name=onebox")
      } else {
        let biz_active = VueCookies.get("biz_active")

        // ยิง API getprofile
        this.$store
          .dispatch("authorize_checking")
          .then((msg) => {
            this.loading_azure = false;
            if (msg.status === "Access Granted") {
              // this.$router.push({ name: "myfiles" });
              
              let payload_bu = {
                taxid: biz_active?.taxid,
                accesstoken: window.atob(VueCookies.get("token")),
                biz_id: biz_active?.biz_id,
                branch_no: biz_active?.branch_no || '00000',
              };
              // ยิง API Login Business
              this.$store.dispatch("switchaccount_business", payload_bu).then(async (msg) => {
                if (msg.status === "Business Access Granted") {
                  this.$router.push({ name: "directory", params: { id: this.dataAccountActive.business_info.my_folder_id } });
                }
              });
            } else {
              Toast.fire({
                icon: "error",
                title: msg.msg
              });
            }
          });
      }
    },
    countdownTime(message) {
      const pattern = /\d+:\d+:\d+(\.\d+)?/
      const match = message.match(pattern);
      if (match) {
        const timeString = match[0];
        const timeParts = timeString.split(':');
        const hours = parseInt(timeParts[0]);
        const minutes = parseInt(timeParts[1]);
        const seconds = parseFloat(timeParts[2]);
        const totalSeconds = Math.ceil((hours * 3600) + (minutes * 60) + seconds);
        console.log('totalSeconds',totalSeconds);
        return totalSeconds;
      } else {
        return 0;
      }
    },
    checkFailLogin(message) {      
      console.log('faillll', this.failedLogin)      
      // let mapFailAttampt = {
      //   '5': 1 * 60 * 1000,
      //   '10': 3 * 60 * 1000,
      //   '15': 10 * 60 * 1000,
      //   '20': 30 * 60 * 1000,
      // }
      // if (mapFailAttampt[this.failedLogin]) {
        // this.isFailLogin = true
        this.countDownFailedLogin = this.countdownTime(message);
        if (this.timer) {
          clearInterval(this.timer)
        }
        this.timer = setInterval(()=>{
          // let nextTime = (parseInt(localStorage.getItem("timeFailedLogin")) + mapFailAttampt[this.failedLogin]) || new Date().getTime();
          // let now = new Date().getTime();
          // console.log('nextTime',nextTime);
          // console.log('now',now);
          // this.countDownFailedLogin = Math.ceil((nextTime - now) / 1000)
          this.countDownFailedLogin--
          console.log('this.countDownFailedLogin',this.countDownFailedLogin);
          if (this.countDownFailedLogin <= 0) {
            this.countDownFailedLogin = 0;
            // this.isFailLogin = false
            clearInterval(this.timer)
          }
        }, 1000)
      // } else {
      //   this.countDownFailedLogin = 0;
      //   this.isFailLogin = false
      //   clearInterval(this.timer)
      // }
    }
  },
  mounted() {
    this.system_type_run = process.env.VUE_APP_ENVIRONMENT
    if (
      this.$route.path === "/moph/loginwithid" ||
      VueCookies.get("check_mohpromt") == "true"
    ) {
      VueCookies.set("checkpathmoppromt", true);
      this.tab_idcard = true;
      this.step1 = 1;
      this.mohpormt = true;
    } else {
      VueCookies.set("checkpathmoppromt", false);
    }
    // this.username_show = localStorage.getItem("username");
    if (VueCookies.get("username") !== null && VueCookies.get("username") !== "" && VueCookies.get("username") !== undefined) {
      this.username_show = CryptoJS.AES.decrypt(VueCookies.get("username"), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
      this.username_show = window.atob(this.username_show.toString(CryptoJS.enc.Utf8));
    }
    // if (
    //   VueCookies.get("checklogin") == false ||
    //   VueCookies.get("checklogin") === null
    // ) {
    //   this.checkbox_remember = false;
    // } else {
    //   this.checkbox_remember = true;
    // }
    if (
      (VueCookies.get("token") == null &&
      sessionStorage.getItem("token") === null) ||
      VueCookies.get("username") === null
    ) {
      this.checkbox_remember = false;
    } else {
      this.checkbox_remember = true;
    }
    
    // if (localStorage.getItem("loginType") === "azure" && VueCookies.get("SessionExpired") === "true") {
    if (VueCookies.get("loginType") === "azure" && VueCookies.get("SessionExpired") === "true") {
      this.dialogSessionExpired = true;
      VueCookies.remove("SessionExpired");
    }
    document.getElementById("vid").play();
    sessionStorage.removeItem("onechat_token");
    sessionStorage.removeItem("onechat_plugin_enable");
    console.log(
      "testt",
      sessionStorage.getItem("onechat_plugin_enable"),
      sessionStorage.removeItem("onechat_token")
    );
    if (VueCookies.get("checklogin") === null) {
      if (sessionStorage.getItem("checklogin") === null) {
        localStorage.removeItem("token")
        //localStorage.removeItem("username");
        //localStorage.removeItem("username_otp")
        localStorage.removeItem("thai_email");
        localStorage.removeItem("user_id")
        localStorage.removeItem("loginType")
        VueCookies.remove("username_otp"); // เพิ่มใหม่
        VueCookies.remove("username"); // เพิ่มใหม่
        VueCookies.remove("check_btn_login_new");
        VueCookies.remove("login_datetime")
        VueCookies.remove("data_id")
        VueCookies.remove("continue_otp")
        VueCookies.remove("reason_otp")
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("token_auth");
        sessionStorage.removeItem("checklogin");
        sessionStorage.removeItem("user_id");
      }
    }

    //กระดิ่ง alert แจ้งเตือน lock function
    this.check_show_alert = VueCookies.get("checkalert");
    if (VueCookies.get("checkalert") === null) {
      if (
        localStorage.getItem("checkshow") === "false" ||
        localStorage.getItem("checkshow") === undefined ||
        localStorage.getItem("checkshow") === null
      ) {
        // this.opendialognewstorage = true;
      } else {
        this.opendialognewstorage = false;
        this.checkbuttonalert = false;
      }
    } else {
      this.opendialognewstorage = false;
      this.checkbuttonalert = false;
    }

    // if (VueCookies.get("checklogin") === null) {
    //   VueCookies.remove("token");
    //   VueCookies.remove("token_auth");
    //   VueCookies.remove("checklogin");
    //   // this.$router.push("logout");
    // } else {
    //   //ไม่จำ
    //   if (VueCookies.get("checklogin") === "false") {
    //     VueCookies.remove("token");
    //     VueCookies.remove("token_auth");
    //     VueCookies.remove("checklogin");
    //     // this.$router.push("logout");
    //     location.assign(process.env.VUE_APP_ONEID + "/api/oauth/logout");
    //   }
    // }
  },
  created() {
    sessionStorage.removeItem("onechat_token");
    sessionStorage.removeItem("onechat_plugin_enable");
    // this.checkFailLogin()
    // this.$router.push("logout");
  },
};
</script>
<style scoped>
body {
  display: grid;
  justify-content: center;
  align-items: center;

  height: 100vh;
  padding: 0;
  margin: 0;
}
@-webkit-keyframes bounce {
  0% {
    transform: scale(1, 1) translate(0px, 0px);
  }

  30% {
    transform: scale(1, 0.8) translate(0px, 10px);
  }

  75% {
    transform: scale(1, 1.1) translate(0px, -25px);
  }

  100% {
    transform: scale(1, 1) translate(0px, 0px);
  }
}

.bounce {
  text-align: center;
  margin: 0 auto;
  /* margin-top:100px; */
  height: 20px;
  width: 150px;
  padding: 20px;
  -webkit-animation: bounce 1.5s infinite;
}
div#alertnews {
  position: fixed;
  right: -30px;
  bottom: 60px;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: 12px;
  padding: 3px;
}
.btn-active {
  background-image: linear-gradient(to right, #184966, #5ab685);
  color: white;
}
.vue-otp-input >>> .separate-input-class {
  text-align: center;
  background-color: white;
  width: 50px;
  height: 55px;
  border: solid 1px rgba(128, 128, 128, 0.700);
}
/* mobile */
@media only screen and (max-width: 500px) {
  .vue-otp-input >>> .separate-input-class {
    width: 40px;
  }
}
/* .v-chip-group .v-slide-group__content {
    padding: 4px 0;
    justify-content: space-between;
}
.v-application .justify-space-between {
    -webkit-box-pack: justify!important;
    -ms-flex-pack: justify!important;
    justify-content: space-between!important;
} */
</style>
